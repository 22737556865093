import Collapse, {
  CollapseActiveKeysType,
} from "@hellodarwin/core/lib/components/common/Collapse";
import Div from "@hellodarwin/core/lib/components/common/div";
import Dropdown from "@hellodarwin/core/lib/components/common/dropdown";
import Grid from "@hellodarwin/core/lib/components/common/hd-grid";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Grant, GrantResult } from "@hellodarwin/core/lib/features/entities";
import { getStringDate } from "@hellodarwin/core/lib/features/helpers";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import CancelProject from "@hellodarwin/icons/dist/icons/CancelProject";
import Marketing from "@hellodarwin/icons/dist/icons/Marketing";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import TabArrowIcon from "@hellodarwin/icons/dist/icons/TabArrow";
import Verified from "@hellodarwin/icons/dist/icons/Verified";
import Button from "antd/es/button";
import Form from "antd/es/form";
import InputNumber from "antd/es/input-number";
import Input from "antd/es/input/Input";
import { MenuProps } from "antd/es/menu";
import Select from "antd/es/select";
import Table, { ColumnsType } from "antd/es/table";
import { ReactNode, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  fetchGrantFinancingType,
  fetchGrantProviders,
  fetchGrantService,
  selectGrantFinancingType,
  selectGrantProviders,
  selectGrantService,
} from "../../features/api/slices/grants-slice";
import {
  fetchIndustries,
  selectIndustriesSectors,
} from "../../features/api/slices/tags-slice";
import { useAdminApi } from "../../features/api/use-admin-api";

export type GrantFilterValues = {
  filterBy: string;
  amountNeeded: number;
  numberEmployee: number;
  financingType: string[];
  industry: string[];
  service: string[];
  textQuery: string;
  grantProvider: string[];
  status: string[];
};

const GrantsList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [filterform] = Form.useForm<GrantFilterValues>();
  const financingType = useAppSelector(selectGrantFinancingType);
  const industries = useAppSelector(selectIndustriesSectors);
  const services = useAppSelector(selectGrantService);
  const providers = useAppSelector(selectGrantProviders);
  const [activeTab, setActiveTab] = useState<CollapseActiveKeysType>("");
  const { selectedLocale } = useLocale();

  const DEFAULT_PAGE = 1;
  const DEFAULT_LIMIT = 100;

  type PageState = {
    grants: Grant[];
    pagination: {
      page: number;
      size: number;
    };
  };

  useEffect(() => {
    fetch(DEFAULT_PAGE, DEFAULT_LIMIT, false, false);
    dispatch(fetchGrantFinancingType({ api, locale: selectedLocale }));
    dispatch(fetchIndustries({ api, locale: selectedLocale }));
    dispatch(fetchGrantService({ api, locale: selectedLocale }));
    dispatch(
      fetchGrantProviders({
        api,
        locale: selectedLocale,
        page: DEFAULT_PAGE,
        limit: 1000,
        query: "",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageStateInitialValues: PageState = {
    grants: [],
    pagination: {
      page: DEFAULT_PAGE,
      size: DEFAULT_LIMIT,
    },
  };
  const [pageState, setPageState] = useState<PageState>(pageStateInitialValues);
  const [apiState, setApiState] = useState<{
    isLoading: boolean;
    isSearching: boolean;
    isErrored: Error | null;
  }>({
    isLoading: false,
    isSearching: false,
    isErrored: null,
  });

  const fetch = (
    page: number,
    size: number,
    loading: boolean,
    isSearching: boolean
  ) => {
    (async () => {
      setApiState({
        isLoading: loading,
        isSearching: isSearching,
        isErrored: null,
      });
      try {
        setApiState({ isLoading: true, isSearching: true, isErrored: null });
        const filterValues = filterform.getFieldsValue()!;
        var response: GrantResult[];

        response = await api.queryGrants(
          selectedLocale,
          page,
          size,
          filterValues.amountNeeded,
          filterValues.numberEmployee,
          filterValues.financingType,
          filterValues.industry,
          filterValues.service,
          filterValues.grantProvider,
          filterValues.status,
          filterValues.textQuery,
          filterValues.filterBy
        );

        const newPageState = {
          grants: response?.map((grant, index) => ({
            key: index,
            ...grant,
          })),
          pagination: { page: page, size: size },
        };
        setPageState(newPageState);
        setApiState({ isLoading: false, isSearching: false, isErrored: null });
      } catch (e: any) {
        setApiState({ isLoading: false, isSearching: false, isErrored: e });
        console.error(e);
      }
    })();
  };

  const onPageChange = (page: number, size: number) => {
    fetch(page, size, true, false);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Grant",
      dataIndex: "grant_id",
      key: "grant_id",
      render: (grantID, record) => {
        return (
          <Div flex="row" gap={16}>
            {record.verified === "Verified" ? (
              <Verified
                size={20}
                color={theme.colors.purple_1}
                style={{ flexShrink: 0 }}
              />
            ) : (
              <CancelProject
                size={20}
                color={theme.colors.red_1}
                style={{ flexShrink: 0 }}
              />
            )}
            {record.promoted_at && (
              <Marketing
                size={20}
                color={theme.colors.purple_1}
                style={{ flexShrink: 0 }}
              />
            )}
            <Link
              target="_blank"
              to={`/grants/${grantID}`}
              style={{ flexShrink: 0 }}
            >
              <NewWindow size={20} />
            </Link>
          </Div>
        );
      },
      width: 4,
    },
    {
      title: "Title",
      dataIndex: "grant_title",
      key: "grant_title",
      ellipsis: true,
      width: 40,
      render: (title) => (
        <Typography elementTheme="body2" ellipsis nowrap overflow>
          {title}
        </Typography>
      ),
    },
    {
      title: "Status",
      dataIndex: "application_status",
      key: "application_status",
      width: 10,
      render: (status) => {
        return (
          <Typography elementTheme="body2">
            {t(`grants|grantCardStatus.${status}`)}
          </Typography>
        );
      },
    },
    {
      title: "Opening Date",
      dataIndex: "grant_timeline",
      key: "grant_timeline_opened_at",
      width: 15,
      render: (grant_timeline) => {
        return Array.isArray(grant_timeline) &&
          grant_timeline.length > 0 &&
          !!grant_timeline[0].opened_at ? (
          <Typography elementTheme="body2">
            {getStringDate(
              new Date(grant_timeline[0].opened_at),
              selectedLocale
            )}
          </Typography>
        ) : null;
      },
    },
    {
      title: "Closing Date",
      dataIndex: "grant_timeline",
      key: "grant_timeline_closed_at",
      width: 15,
      render: (grant_timeline) => {
        return Array.isArray(grant_timeline) &&
          grant_timeline.length > 0 &&
          grant_timeline[0].closed_at ? (
          <Typography elementTheme="body2">
            {getStringDate(
              new Date(grant_timeline[0].closed_at),
              selectedLocale
            )}
          </Typography>
        ) : null;
      },
    },
  ];

  const applyFilter = async () => {
    fetch(DEFAULT_PAGE, DEFAULT_LIMIT, true, false);
  };

  const GrantFilter = (): ReactNode => (
    <Div flex="row" align="center" gap={24}>
      <Button
        onClick={applyFilter}
        size="small"
        style={{ height: "fit-content" }}
      >
        Apply Filter
      </Button>
      <Form form={filterform} layout="vertical" style={{ width: "100%" }}>
        <Grid xl={3} lg={3} md={3} gutter={24} style={{ width: "100%" }}>
          <Form.Item label={"Sort by"} name="filterBy" style={{ margin: 0 }}>
            <Select
              placeholder={"Filter by"}
              defaultValue={""}
              onChange={applyFilter}
            >
              <Select.Option value="">{"Recommended"}</Select.Option>
              <Select.Option value="opening-soon">
                {"Opening Soon"}
              </Select.Option>
              <Select.Option value="closing-soon">
                {"Closing Soon"}
              </Select.Option>
              <Select.Option value="latest-created">
                {"Latest Created"}
              </Select.Option>
              <Select.Option value="oldest-created">
                {"Oldest Created"}
              </Select.Option>
              <Select.Option value="latest-updated">
                {"Latest Updated"}
              </Select.Option>
              <Select.Option value="oldest-updated">
                {"Oldest Updated"}
              </Select.Option>
              <Select.Option value="promoted">{"Only Promoted"}</Select.Option>
              <Select.Option value="verified">{"Only Verified"}</Select.Option>
              <Select.Option value="unverified">
                {"Only Unverified"}
              </Select.Option>
              <Select.Option value="rejected">{"Only Rejected"}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Amount Needed : "
            name="amountNeeded"
            style={{ margin: 0 }}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Number of Employee : "
            name="numberEmployee"
            style={{ margin: 0 }}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Financing Type : "
            name="financingType"
            style={{ margin: 0 }}
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              options={financingType}
              filterOption={(input, option) =>
                option?.label?.toLowerCase().indexOf(input?.toLowerCase()) !==
                -1
              }
            />
          </Form.Item>
          <Form.Item
            label="Industry sectors : "
            name="industry"
            style={{ margin: 0 }}
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              options={industries}
              filterOption={(input, option) =>
                option?.label?.toLowerCase().indexOf(input?.toLowerCase()) !==
                -1
              }
            />
          </Form.Item>
          <Form.Item label="Services : " name="service" style={{ margin: 0 }}>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              options={services}
              filterOption={(input, option) =>
                option?.label?.toLowerCase().indexOf(input?.toLowerCase()) !==
                -1
              }
            />
          </Form.Item>
          <Form.Item
            label="Grant Provider : "
            name="grantProvider"
            style={{ margin: 0 }}
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              options={providers}
              filterOption={(input, option) =>
                option?.label?.toLowerCase().indexOf(input?.toLowerCase()) !==
                -1
              }
            />
          </Form.Item>
          <Form.Item label="Status" name="status">
            <Select mode="multiple" placeholder="Select option">
              <Select.Option value="open">Open</Select.Option>
              <Select.Option value="suspending">Suspending</Select.Option>
              <Select.Option value="openingSoon">Opening Soon</Select.Option>
              <Select.Option value="closingSoon">Closing Soon</Select.Option>
              <Select.Option value="closed">Closed</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Search by Name: " name="textQuery">
            <Input placeholder="Search... (grant title, description)" />
          </Form.Item>
        </Grid>
      </Form>
    </Div>
  );

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        applyFilter();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSyncAllGrants = async () => {
    api.syncAllGrantList();
  };

  const devActionMenuItems: MenuProps["items"] = [
    {
      label: "sync all grant list with hubspot",
      onClick: handleSyncAllGrants,
      key: 2,
    },
  ];

  const Header = () => {
    const navigate = useNavigate();
    const handleCreateGrant = () => {
      navigate(`/grants/create`);
    };

    return (
      <Div flex="column" gap={12}>
        <Div flex="row" gap={16} justify="flex-end">
          <Link to={`/grants/create`}>
            <Button onClick={handleCreateGrant}>Create a Grant</Button>
          </Link>
          <Dropdown items={devActionMenuItems} cta={{ size: 32 }} />
        </Div>
        <Collapse
          activeKey={activeTab}
          onChange={setActiveTab}
          items={[
            {
              id: "filters",
              title: "Filters",
              children: <GrantFilter />,
            },
          ]}
          ghost
          noPadding
          styles={{
            panelHeader: {
              borderBottom: `1px solid ${theme.colors.grey_1}`,
              padding: `10px 0`,
              marginBottom: 20,
            },
          }}
          expandIconPosition="center"
          ExpandIcon={({ isActive }) => (
            <TabArrowIcon width={10} height={10} down={!isActive} />
          )}
        />
      </Div>
    );
  };
  return (
    <Div flex="column" style={{ marginTop: 24 }} gap={24}>
      <Header />
      <Table
        bordered
        dataSource={pageState.grants}
        columns={columns}
        pagination={{
          pageSize: pageState.pagination.size,
          current: pageState.pagination.page,
          total: pageState.grants?.[0]?.total_count,
          showSizeChanger: true,
          onChange: (page, size) => onPageChange(page, size ?? DEFAULT_LIMIT),
        }}
        loading={apiState.isLoading}
      />
    </Div>
  );
};

export default GrantsList;

