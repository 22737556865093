import Checkbox from "antd/es/checkbox";
import DatePicker from "antd/es/date-picker";
import Input from "antd/es/input";
import TextArea from "antd/es/input/TextArea";
import Radio from "antd/es/radio";
import Select from "antd/es/select";
import Space from "antd/es/space";
import React from "react";
import {
  FormAssetList,
  TranslatedFormItemResponse,
} from "../../../features/entities/form-entities";
import parse from "../../../features/helpers/parse";
import useLocale from "../../../features/providers/locale-provider";
import { itemDescriptionOptions } from "../form-builder/utils/form-parse-options";
import FileUploader from "../form-groups/uploader/file";
import FormItem from "../form-layouts/form-item";
import FormLabel from "../form-layouts/form-label";
import { getSelectOptions } from "./utils";

interface FormInputProps {
  formItem: TranslatedFormItemResponse;
  formAssetList?: FormAssetList[];
  setFormAssetList?: React.Dispatch<React.SetStateAction<FormAssetList[]>>;
}

const FormInput = (props: FormInputProps) => {
  const { formItem } = props;
  const { selectedLocale } = useLocale();

  const options = formItem.form_select_options
    ? getSelectOptions(formItem.form_select_options, selectedLocale)
    : [];

  return (
    <FormItem
      label={
        <FormLabel
          label={formItem.content}
          extra={
            !!formItem.heading &&
            parse(formItem.heading, itemDescriptionOptions)
          }
        />
      }
      style={{ maxWidth: "100%", flex: 1 }}
      name={formItem.form_item_id}
      rules={[
        {
          required: formItem.required === "required",
          message: "This field is required",
        },
      ]}
    >
      {formItem.type === "string" ? (
        formItem.field_type === "text" ? (
          <TextArea />
        ) : (
          <Input />
        )
      ) : formItem.type === "datetime" ? (
        formItem.field_type === "date" ? (
          <DatePicker picker="date" />
        ) : (
          <DatePicker picker="time" />
        )
      ) : formItem.type === "enum" ? (
        formItem.field_type === "select" ? (
          <Select style={{ width: 400, maxWidth: "100%" }} options={options} />
        ) : formItem.field_type === "radio" ? (
          <Radio.Group>
            <Space
              direction={
                formItem.disposition === "vertical" ? "vertical" : "horizontal"
              }
            >
              {React.Children.toArray(
                options.map((option) => (
                  <Radio value={option.value}>{option.label}</Radio>
                ))
              )}
            </Space>
          </Radio.Group>
        ) : formItem.field_type === "checkbox" ? (
          <Checkbox.Group>
            <Space
              direction={
                formItem.disposition === "vertical" ? "vertical" : "horizontal"
              }
            >
              {React.Children.toArray(
                options.map((option) => (
                  <Checkbox value={option.value}>{option.label}</Checkbox>
                ))
              )}
            </Space>
          </Checkbox.Group>
        ) : (
          <></>
        )
      ) : formItem.type === "file" ? (
        formItem.field_type === "attachment" ? (
          <FileUploader
            fileList={
              props.formAssetList.find(
                (asset) => asset.form_item_id === formItem.form_item_id
              )?.assets || []
            }
            name={formItem.heading}
            handleChange={(newFiles) => {
              if (props.setFormAssetList) {
                props.setFormAssetList((prev) => [
                  ...prev.filter(
                    (asset) => asset.form_item_id !== formItem.form_item_id
                  ),
                  {
                    form_item_id: formItem.form_item_id,
                    form_item_label:
                      formItem.content ??
                      formItem.heading ??
                      formItem.form_item_id,
                    assets: newFiles,
                  },
                ]);
              }
            }}
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </FormItem>
  );
};

export default FormInput;

