import Typography from "@hellodarwin/core/lib/components/common/typography";
import SummaryBox from "@hellodarwin/core/lib/components/grants/single/grant-sections/summary/SummaryBox";
import { IconNode } from "@hellodarwin/icons/dist/features/entities/general";
import React, { PropsWithChildren, useMemo } from "react";
import { GinGrantSummaryWrapper } from "./styles";

export interface GinSummaryBlock extends PropsWithChildren {
  label: string;
  Icon: IconNode;
}
export interface GinSummaryProps {
  blocks: GinSummaryBlock[];
}
const GinGrantSummary = ({ blocks }: GinSummaryProps) => {
  const size = useMemo(() => blocks.length, [blocks]);
  return (
    <GinGrantSummaryWrapper $size={size}>
      {React.Children.toArray(
        blocks.map((block) => (
          <SummaryBox
            {...block}
            children={
              <Typography elementTheme="body2">{block.children}</Typography>
            }
          />
        ))
      )}
    </GinGrantSummaryWrapper>
  );
};

export default GinGrantSummary;

