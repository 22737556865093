import {
  AdminGrantResult,
  AdminSimilarGrantResult,
  ApiError,
  ApiErrorInitialState,
  GrantApplication,
  GrantContentRaw,
  GrantFinancingType,
  GrantForProfit,
  GrantInitialState,
  GrantPreview,
  GrantProject,
  GrantProvider,
  GrantProviderInitialState,
  GrantProviders,
  GrantResult,
  GrantService,
  GrantTimeline,
  Province,
} from "@hellodarwin/core/lib/features/entities";
import AdminQueryFundingExplorerProps from "@hellodarwin/core/lib/features/entities/admin-entities";
import {
  EntityState,
  PayloadAction,
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/app-store";
import { grantPromptSectionTitles } from "../../../components/grants/grant-form/grant-form-sections";
import { grantTimelineSectionTitle } from "../../../components/grants/grant-form/grant-form-timeline";
import { showErrorNotification } from "../../utils";
import AdminApi from "../admin-api";
import AdminApiClient from "../admin-api-client";

export const fetchGrant = createAsyncThunk<
  AdminGrantResult,
  { api: AdminApi; grantId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGrant",
  async (
    { api, grantId }: { api: AdminApi; grantId: string },
    { rejectWithValue }
  ) => {
    try {
      return (await api.get<AdminGrantResult>(`/grants/admin/${grantId}`)).data;
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantProvider = createAsyncThunk<
  GrantProvider[],
  { api: AdminApiClient; grantProviderId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGrantProvider",
  async (
    { api, grantProviderId }: { api: AdminApiClient; grantProviderId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrantProviderById(grantProviderId);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const createGrantContentRaw = createAsyncThunk<
  GrantContentRaw,
  { api: AdminApiClient; grantId: string; locale: string },
  { rejectValue: ApiError }
>(
  "admin/createGrantContentRaw",
  async (
    {
      api,
      grantId,
      locale,
    }: { api: AdminApiClient; grantId: string; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.createGrantContentRaw(grantId, locale);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantContentRaw = createAsyncThunk<
  GrantContentRaw,
  { api: AdminApiClient; grantId: string; locale: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGrantContentRaw",
  async (
    {
      api,
      grantId,
      locale,
    }: { api: AdminApiClient; grantId: string; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrantContentRaw(grantId, locale);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantFinancingType = createAsyncThunk<
  GrantFinancingType[],
  { api: AdminApiClient; locale: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGrantFinancingType",
  async (
    { api, locale }: { api: AdminApiClient; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrantFinancingType(locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantService = createAsyncThunk<
  GrantService[],
  { api: AdminApiClient; locale: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGrantService",
  async (
    { api, locale }: { api: AdminApiClient; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrantService(locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantForProfit = createAsyncThunk<
  GrantForProfit[],
  { api: AdminApiClient; locale: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGrantForProfit",
  async (
    { api, locale }: { api: AdminApiClient; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrantForProfit(locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantProviders = createAsyncThunk<
  GrantProvider[],
  {
    api: AdminApiClient;
    locale: string;
    page: number;
    limit: number;
    query: string;
  },
  { rejectValue: ApiError }
>(
  "admin/fetchGrantProviders",
  async (
    {
      api,
      locale,
      page,
      limit,
      query,
    }: {
      api: AdminApiClient;
      locale: string;
      page: number;
      limit: number;
      query: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.queryGrantProviders(locale, page, limit, query);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantProvidersByGrantId = createAsyncThunk<
  GrantProvider[],
  { api: AdminApiClient; locale: string; grantId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGrantProvidersByGrantId",
  async (
    {
      api,
      locale,
      grantId,
    }: { api: AdminApiClient; locale: string; grantId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrantProvidersByGrantId(locale, grantId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchProvinces = createAsyncThunk<
  Province[],
  { api: AdminApiClient; locale: string },
  { rejectValue: ApiError }
>(
  "admin/fetchProvinces",
  async (
    { api, locale }: { api: AdminApiClient; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchProvinces(locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantProvidedByProvider = createAsyncThunk<
  GrantPreview[],
  { api: AdminApiClient; grantProviderId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGrantProvidedByProvider",
  async (
    { api, grantProviderId }: { api: AdminApiClient; grantProviderId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrantProvidedByProvider(grantProviderId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const createGrant = createAsyncThunk<
  AdminSimilarGrantResult,
  { api: AdminApiClient; grant: AdminGrantResult },
  { rejectValue: ApiError }
>(
  "admin/createGrant",
  async (
    { api, grant }: { api: AdminApiClient; grant: AdminGrantResult },
    { rejectWithValue }
  ) => {
    try {
      return await api.createGrant(grant);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteGrant = createAsyncThunk<
  string,
  { api: AdminApiClient; grantID: string },
  { rejectValue: ApiError }
>(
  "admin/deleteGrant",
  async (
    { api, grantID }: { api: AdminApiClient; grantID: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.deleteGrant(grantID);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchSimilarGrants = createAsyncThunk<
  string[],
  { api: AdminApiClient; grantID: string },
  { rejectValue: ApiError }
>(
  "admin/fetchSimilarGrants",
  async (
    { api, grantID }: { api: AdminApiClient; grantID: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchSimilarGrants(grantID);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const createGrantProvider = createAsyncThunk<
  GrantProviders,
  { api: AdminApiClient; grantProvider: GrantProviders },
  { rejectValue: ApiError }
>(
  "admin/createGrantProvider",
  async (
    {
      api,
      grantProvider,
    }: { api: AdminApiClient; grantProvider: GrantProviders },
    { rejectWithValue }
  ) => {
    try {
      return await api.createGrantProvider(grantProvider);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const queryFundingExplorer = createAsyncThunk<
  GrantResult[],
  {
    api: AdminApiClient;
  } & AdminQueryFundingExplorerProps,
  { rejectValue: ApiError }
>(
  "client/queryFundingExplorer",
  async (
    {
      api,
      ...props
    }: {
      api: AdminApiClient;
    } & AdminQueryFundingExplorerProps,
    { rejectWithValue }
  ) => {
    try {
      return await api.queryFundingExplorer(props);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const generateSingleGrantContentInfo = createAsyncThunk<
  string,
  {
    api: AdminApiClient;
    grantId: string;
    sectionName: string;
    industrySectorsIDs?: string[];
  },
  { rejectValue: ApiError }
>(
  "admin/generateSingleGrantContentInfo",
  async (
    {
      api,
      grantId,
      sectionName,
      industrySectorsIDs,
    }: {
      api: AdminApiClient;
      grantId: string;
      sectionName: string;
      industrySectorsIDs?: string[];
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.generateSingleGrantContentInfo(
        grantId,
        sectionName,
        industrySectorsIDs
      );
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const generateAllGrantProjects = createAsyncThunk<
  GrantProject[],
  { api: AdminApiClient; grantId: string; grantProjects: GrantProject[] },
  { rejectValue: ApiError }
>(
  "admin/generateAllGrantProjects",
  async (
    {
      api,
      grantId,
      grantProjects,
    }: { api: AdminApiClient; grantId: string; grantProjects: GrantProject[] },
    { rejectWithValue }
  ) => {
    try {
      return await api.generateAllGrantProjects(grantId, grantProjects);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const generateGrantTimelines = createAsyncThunk<
  GrantTimeline[],
  { api: AdminApiClient; grantId: string },
  { rejectValue: ApiError }
>(
  "admin/generateGrantTimlines",
  async (
    { api, grantId }: { api: AdminApiClient; grantId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.generateGrantTimeline(grantId);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGrant = createAsyncThunk<
  AdminGrantResult,
  { api: AdminApiClient; grant: AdminGrantResult },
  { rejectValue: ApiError }
>(
  "admin/updateGrant",
  async (
    { api, grant }: { api: AdminApiClient; grant: AdminGrantResult },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateGrant(grant);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGrantPromoted = createAsyncThunk<
  string,
  { api: AdminApiClient; grant_id: string; promoted: boolean },
  { rejectValue: ApiError }
>(
  "admin/updateGrantPromoted",
  async (
    {
      api,
      grant_id,
      promoted,
    }: { api: AdminApiClient; grant_id: string; promoted: boolean },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateGrantPromoted(grant_id, promoted);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGrantProvider = createAsyncThunk<
  GrantProviders,
  { api: AdminApiClient; grantProvider: GrantProviders },
  { rejectValue: ApiError }
>(
  "admin/updateGrantProvider",
  async (
    {
      api,
      grantProvider,
    }: { api: AdminApiClient; grantProvider: GrantProviders },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateGrantProvider(grantProvider);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGrantContentRaw = createAsyncThunk<
  GrantContentRaw,
  { api: AdminApiClient; grantContentRaw: GrantContentRaw },
  { rejectValue: ApiError }
>(
  "admin/updateGrantContentRaw",
  async (
    {
      api,
      grantContentRaw,
    }: { api: AdminApiClient; grantContentRaw: GrantContentRaw },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateGrantContentRaw(grantContentRaw);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGrantProviderLogo = createAsyncThunk<
  GrantProvider,
  {
    api: AdminApiClient;
    grantProviderId: string;
    locale: string;
    data: FormData;
  },
  { rejectValue: ApiError; state: RootState }
>(
  "admin/updateGrantProviderLogo",
  async (
    {
      api,
      grantProviderId,
      locale,
      data,
    }: {
      api: AdminApiClient;
      grantProviderId: string;
      locale: string;
      data: FormData;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateGrantProviderLogo(grantProviderId, locale, data);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGrantLogo = createAsyncThunk<
  GrantResult,
  { api: AdminApiClient; grantId: string; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  "admin/updateGrantLogo",
  async (
    {
      api,
      grantId,
      data,
    }: { api: AdminApiClient; grantId: string; data: FormData },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateGrantLogo(grantId, data);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantApplication = createAsyncThunk<
  GrantApplication,
  { api: AdminApiClient; id: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGrantApplication",
  async (
    { api, id }: { api: AdminApiClient; id: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrantApplication(id);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateGrantApplication = createAsyncThunk<
  GrantApplication,
  { api: AdminApiClient; id: string; content: string },
  { rejectValue: ApiError }
>(
  "admin/updateGrantApplication",
  async (
    { api, id, content }: { api: AdminApiClient; id: string; content: string },
    { rejectWithValue }
  ) => {
    try {
      console.error(content, "content");
      return await api.updateGrantApplication(id, content);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const grantsResultAdapter = createEntityAdapter({
  selectId: (model: GrantResult) => model.grant_id,
});

const adminGrantsAdapter = createEntityAdapter({
  selectId: (model: AdminGrantResult) => model.grant_id,
});

const grantProviderAdapter = createEntityAdapter({
  selectId: (model: GrantProvider[]) => model[0].grant_provider_id,
});

const grantApplicationsAdapter = createEntityAdapter({
  selectId: (model: GrantApplication) => model.id,
});

const grantContentRawAdapter = createEntityAdapter({
  selectId: (model: GrantContentRaw) => model.grant_id,
});

export const setActiveTab = createAction<string>("admin/setActiveTab");
export const selectActiveTab = (state: RootState) => state.projects.activeTab;

export interface GrantState {
  status: "idle" | "pending";
  grantStatus: "idle" | "pending";
  error: ApiError;
  selectedGrant: GrantResult;
  grants: EntityState<GrantResult, string>;
  selectGrantProvider: GrantProvider;
  grantProvider: EntityState<GrantProvider[], string>;
  grantFinancingType: GrantFinancingType[];
  grantProviders: GrantProvider[];
  grantPreview: GrantPreview[];
  provinces: Province[];
  grantService: GrantService[];
  grantForProfit: GrantForProfit[];
  activeTab: string;
  grantApplications: EntityState<GrantApplication, string>;
  grantContentRaw: EntityState<GrantContentRaw, string>;
  loadingMap: Record<string, boolean>;
}

const initialState: GrantState = {
  status: "idle",
  grantStatus: "pending",
  error: ApiErrorInitialState,
  grants: grantsResultAdapter.getInitialState(),
  selectedGrant: GrantInitialState,
  selectGrantProvider: GrantProviderInitialState,
  grantProvider: grantProviderAdapter.getInitialState(),
  grantFinancingType: [],
  grantProviders: [],
  grantPreview: [],
  provinces: [],
  grantService: [],
  grantForProfit: [],
  activeTab: "",
  grantApplications: grantApplicationsAdapter.getInitialState(),
  grantContentRaw: grantContentRawAdapter.getInitialState(),
  loadingMap: {},
};

const grantsSlice = createSlice({
  name: "grants",
  initialState,
  reducers: {
    setLoadingSection: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.loadingMap[id] = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGrant.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGrant.fulfilled, (state, { payload }) => {
      adminGrantsAdapter.upsertOne(state.grants, payload);
      state.status = "idle";
    });
    builder.addCase(fetchGrant.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchGrantProvider.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGrantProvider.fulfilled, (state, { payload }) => {
      grantProviderAdapter.upsertOne(state.grantProvider, payload);
      state.status = "idle";
    });
    builder.addCase(fetchGrantProvider.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchGrantFinancingType.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGrantFinancingType.fulfilled, (state, { payload }) => {
      state.grantFinancingType = payload;
      state.status = "idle";
    });
    builder.addCase(fetchGrantFinancingType.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchGrantService.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGrantService.fulfilled, (state, { payload }) => {
      state.grantService = payload;
      state.status = "idle";
    });
    builder.addCase(fetchGrantService.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchGrantForProfit.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGrantForProfit.fulfilled, (state, { payload }) => {
      state.grantForProfit = payload;
      state.status = "idle";
    });
    builder.addCase(fetchGrantForProfit.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchGrantProviders.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGrantProviders.fulfilled, (state, { payload }) => {
      state.grantProviders = payload;

      state.status = "idle";
    });
    builder.addCase(fetchGrantProviders.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchGrantProvidersByGrantId.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(
      fetchGrantProvidersByGrantId.fulfilled,
      (state, { payload }) => {
        state.grantProviders = payload;

        state.status = "idle";
      }
    );
    builder.addCase(
      fetchGrantProvidersByGrantId.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = "idle";
      }
    );
    builder.addCase(fetchProvinces.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchProvinces.fulfilled, (state, { payload }) => {
      state.provinces = payload;
      state.status = "idle";
    });
    builder.addCase(fetchProvinces.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchGrantProvidedByProvider.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(
      fetchGrantProvidedByProvider.fulfilled,
      (state, { payload }) => {
        state.grantPreview = payload;
        state.status = "idle";
      }
    );
    builder.addCase(
      fetchGrantProvidedByProvider.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = "idle";
      }
    );
    builder.addCase(updateGrantProviderLogo.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateGrantProviderLogo.fulfilled, (state, { payload }) => {
      state.selectGrantProvider = payload;
      state.status = "idle";
    });
    builder.addCase(updateGrantProviderLogo.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(updateGrantLogo.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateGrantLogo.fulfilled, (state, { payload }) => {
      state.selectedGrant = payload;
      state.status = "idle";
    });
    builder.addCase(updateGrantLogo.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(setActiveTab, (state, { payload }) => {
      state.activeTab = payload;
    });
    builder.addCase(fetchGrantApplication.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGrantApplication.fulfilled, (state, { payload }) => {
      grantApplicationsAdapter.upsertOne(state.grantApplications, payload);
      state.status = "idle";
    });
    builder.addCase(fetchGrantApplication.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(updateGrantApplication.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateGrantApplication.fulfilled, (state, { payload }) => {
      grantApplicationsAdapter.upsertOne(state.grantApplications, payload);
      state.status = "idle";
    });
    builder.addCase(updateGrantApplication.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchGrantContentRaw.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGrantContentRaw.fulfilled, (state, { payload }) => {
      grantContentRawAdapter.upsertOne(state.grantContentRaw, payload);
      state.status = "idle";
    });
    builder.addCase(fetchGrantContentRaw.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(createGrantContentRaw.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(createGrantContentRaw.fulfilled, (state, { payload }) => {
      grantContentRawAdapter.upsertOne(state.grantContentRaw, payload);
      state.status = "idle";
    });
    builder.addCase(createGrantContentRaw.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(generateSingleGrantContentInfo.pending, (state, action) => {
      state.status = "pending";
      state.loadingMap[action.meta.arg.sectionName] = true;
    });
    builder.addCase(
      generateSingleGrantContentInfo.fulfilled,
      (state, action) => {
        state.status = "idle";
        state.loadingMap[action.meta.arg.sectionName] = false;
      }
    );
    builder.addCase(
      generateSingleGrantContentInfo.rejected,
      (state, action) => {
        state.error = action.payload ?? ApiErrorInitialState;
        state.status = "idle";
        state.loadingMap[action.meta.arg.sectionName] = false;
      }
    );
    builder.addCase(generateGrantTimelines.pending, (state, action) => {
      state.status = "pending";
      state.loadingMap[grantTimelineSectionTitle] = true;
    });
    builder.addCase(generateGrantTimelines.fulfilled, (state, action) => {
      state.status = "idle";
      state.loadingMap[grantTimelineSectionTitle] = false;
    });
    builder.addCase(generateGrantTimelines.rejected, (state, action) => {
      state.error = action.payload ?? ApiErrorInitialState;
      state.status = "idle";
      state.loadingMap[grantTimelineSectionTitle] = false;
    });
    builder.addCase(queryFundingExplorer.pending, (state) => {
      state.grantStatus = "pending";
    });
    builder.addCase(queryFundingExplorer.fulfilled, (state, { payload }) => {
      state.grants = grantsResultAdapter.setAll(
        state.grants,
        payload ? payload : []
      );
      state.grantStatus = "idle";
    });
    builder.addCase(queryFundingExplorer.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.grantStatus = "idle";
    });
  },
});

export const selectGrantsIsLoading = (state: RootState) =>
  state.grants.grantStatus === "pending";

export const selectGrantApplicationIsLoading = (state: RootState) =>
  state.grants.status === "pending";

export const { selectById: selectGrantProviderById } =
  grantProviderAdapter.getSelectors(
    (state: RootState) => state.grants.grantProvider
  );

export const { selectById: selectGrantApplicationById } =
  grantApplicationsAdapter.getSelectors(
    (state: RootState) => state.grants.grantApplications
  );

export const { selectById: selectGrantContentRaw } =
  grantContentRawAdapter.getSelectors(
    (state: RootState) => state.grants.grantContentRaw
  );

export const selectGrantFinancingType = createSelector(
  (state: RootState) => state.grants?.grantFinancingType,
  (grantFinancingType) =>
    grantFinancingType?.map((item) => {
      return {
        label: item.type,
        value: item.grant_financing_type_id,
      };
    })
);

export const selectGrantService = createSelector(
  (state: RootState) => state.grants.grantService,
  (grantService) =>
    grantService?.map((item) => {
      return {
        label: item.name,
        value: item.grant_service_id,
      };
    })
);

export const {
  selectById: selectGrantById,
  selectAll: selectAllFundingExplorerGrants,
} = adminGrantsAdapter.getSelectors((state: RootState) => state.grants.grants);

export const selectGrantForProfit = createSelector(
  (state: RootState) => state.grants.grantForProfit,
  (grantForProfit) => {
    if (!grantForProfit) return [];
    return grantForProfit?.map((item) => {
      return {
        label: item.name,
        value: item.grant_for_profit_id,
      };
    });
  }
);

export const selectGrantProviders = createSelector(
  [(state: RootState) => state.grants.grantProviders],
  (grantProviders) => {
    if (!grantProviders) return [];
    return grantProviders?.map((item) => {
      return {
        label: item.title,
        value: item.grant_provider_id,
      };
    });
  }
);

export const selectProvinces = createSelector(
  (state: RootState) => state.grants.provinces,
  (provinces) => {
    if (!provinces) return [];
    return provinces?.map((item) => {
      return {
        label: item.name,
        value: item.code,
      };
    });
  }
);

export const selectGrantProvidedByProvider = createSelector(
  (state: RootState) => state.grants.grantPreview,
  (grantPreview) => {
    if (!grantPreview) return [];
    return grantPreview?.map((item) => {
      return {
        label: item.verified + " | " + item.display_title,
        value: item.grant_id,
      };
    });
  }
);

export const selectSelectedGrant = (state: RootState) =>
  state.grants.selectedGrant;

export const selectIsLoadingSoloGen = createSelector(
  [
    (state: RootState, _) => state.grants.loadingMap,
    (_, sectionName: string) => sectionName,
  ],
  (loadingMap, sectionName) => loadingMap[sectionName]
);

export const selectIsLoadingGen = createSelector(
  (state: RootState) => state.grants.loadingMap,
  (loadingMap) => {
    return Array.from(Object.values(loadingMap)).some((status) => status);
  }
);

export const selectIsLoadingSectionsGen = createSelector(
  (state: RootState) => state.grants.loadingMap,
  (loadingMap) => {
    for (const section of grantPromptSectionTitles) {
      if (loadingMap[section]) {
        return true;
      }
    }
    return false;
  }
);
export const { setLoadingSection } = grantsSlice.actions;
export const grantsReducer = grantsSlice.reducer;

