import Div from "@hellodarwin/core/lib/components/common/div";
import Loading from "@hellodarwin/core/lib/components/loading";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import Select from "antd/es/select";
import React from "react";

interface CompanyOption {
  key: string;
  value: string;
  label: string;
}

interface SelectCompanyProps {
  companyOptions: CompanyOption[] | undefined;
  isSearching: boolean;
  onSearch: (query: string) => void;
  value: string | undefined;
  onSelectCompany: (value: string | undefined) => void;
}

const SelectCompany: React.FC<SelectCompanyProps> = ({
  companyOptions,
  isSearching,
  onSearch,
  value,
  onSelectCompany,
}) => {
  const { t } = useTranslations();

  return (
    <Select
      showSearch
      options={companyOptions}
      placeholder={t("createApplication|search_company")}
      loading={isSearching}
      filterOption={false}
      onSearch={onSearch}
      onChange={onSelectCompany}
      value={value}
      notFoundContent={
        isSearching ? (
          <Loading />
        ) : !!!companyOptions ? (
          <Div>No companies found</Div>
        ) : null
      }
    />
  );
};

export default SelectCompany;

