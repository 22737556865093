import {
  Admin,
  AdminFormResponse,
  AdminFormSubmission,
  AdminFormSubmissionResponse,
  AdminFormsSubmission,
  AdminGinContactResponse,
  AdminGinResult,
  AdminGrantResult,
  AdminHubspotFormResponse,
  AdminProfile,
  AdminProfileResponse,
  AdminProjectRequest,
  AdminSimilarGrantResult,
  AdminTag,
  BasicProjectForm,
  BillingSession,
  CancelProjectBody,
  ChatType,
  Company,
  CompanyAsset,
  CompanyQuickInfos,
  Contact,
  CreateAuth0UserResponse,
  DealHubspotResponse,
  DocumentNeeded,
  EditableFormSubmissionResponse,
  FindAuth0IDResponse,
  FormGroupResponse,
  FormItemResponse,
  FormPageResponse,
  Forms,
  FormsConditions,
  FormsGroup,
  FormsGroupsItem,
  FormsItemsTotal,
  FormsPage,
  FormsSelectOptions,
  FormsSubmission,
  FormsSubmissionItem,
  Gin,
  GinApplication,
  GinContact,
  GinHistory,
  GinProgram,
  GrantApplication,
  GrantContentRaw,
  GrantFinancingType,
  GrantForProfit,
  GrantPreview,
  GrantProject,
  GrantProjectTag,
  GrantProvider,
  GrantProviders,
  GrantResult,
  GrantService,
  GrantTag,
  GrantTimeline,
  GrantsVectorSearchResponse,
  HdChat,
  HdChatPrompt,
  HdChatPromptRequest,
  HdChatQuestion,
  HdChatSubQuestion,
  HdChatSubQuestionRequest,
  HdChatTemplate,
  HdCreateChatPromptRequest,
  HdCreateChatRequest,
  HdCreateChatTemplateRequest,
  Industry,
  Invoice,
  Match,
  MatchmakingNotification,
  MatchmakingResponse,
  Message,
  Milestone,
  MilestoneAttachment,
  NewTag,
  Portfolio,
  PortfolioAsset,
  PotentialProviderMatch,
  Program,
  Project,
  ProjectAsset,
  ProjectDescription,
  ProjectDescriptionTemplate,
  ProjectSearchResult,
  Provider,
  ProviderProfile,
  ProviderRequest,
  ProviderSearchResult,
  ProviderStatus,
  ProviderTagRequest,
  Province,
  RaiseHandParams,
  ReferralCreditParams,
  Review,
  ReviewNotification,
  ReviewRequest,
  ReviewResult,
  SearchResults,
  ShortlistParams,
  Step,
  Tag,
  TagType,
  TeamMember,
  TeamMemberItem,
  TransferFromGroup,
  TranslatedFormResponse,
  WinFeeParams,
} from "@hellodarwin/core/lib/features/entities";
import AdminQueryFundingExplorerProps from "@hellodarwin/core/lib/features/entities/admin-entities";
import ProjectStatus from "@hellodarwin/core/lib/features/enums/project-status";
import { Token } from "@stripe/stripe-js";
import axios, { AxiosInstance } from "axios";
import { SearchType } from "../../pages/search-grants-page";

export default class AdminApiClient {
  private readonly baseURL: string;
  private readonly accessToken: string;

  constructor(baseUrl: string, accessToken: string) {
    this.baseURL = baseUrl;
    this.accessToken = accessToken;
  }

  createAxios = (contentType: string = "application/json"): AxiosInstance => {
    return axios.create({
      baseURL: this.baseURL + "/admin",
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  };

  fetchProjectEmailActivity = async (projectId: string): Promise<Message[]> => {
    const response = await this.createAxios().get(
      `/notifications/projects/${projectId}/email/activity`
    );

    return response.data;
  };

  fetchProjectMatches = async (projectId: string): Promise<Match[]> => {
    const response = await this.createAxios().get<Match[]>(
      `/projects/${projectId}/matches`
    );

    return response.data;
  };

  resetProjectMatches = async (projectId: string): Promise<string> => {
    const response = await this.createAxios().post<string>(
      `/projects/${projectId}/matches/reset`
    );

    return response.data;
  };

  resetProjectPurchases = async (projectId: string): Promise<string> => {
    const response = await this.createAxios().post<string>(
      `/projects/${projectId}/matches/resetPurchases`
    );

    return response.data;
  };

  resetProjectRaiseHands = async (projectId: string): Promise<string> => {
    const response = await this.createAxios().post<string>(
      `/projects/${projectId}/matches/resetRaiseHands`
    );

    return response.data;
  };

  fetchProjectPurchasedMatches = async (
    projectId: string
  ): Promise<Match[]> => {
    const response = await this.createAxios().get<Match[]>(
      `/projects/${projectId}/matches/purchased`
    );

    return response.data;
  };

  fetchProjectRefusedMatches = async (projectId: string): Promise<Match[]> => {
    const response = await this.createAxios().get<Match[]>(
      `/v2/projects/${projectId}/matches/refused`
    );

    return response.data;
  };

  fetchProjectTags = async (projectId: string): Promise<AdminTag[]> => {
    const response = await this.createAxios().get<AdminTag[]>(
      `/projects/${projectId}/tags`
    );

    return response.data;
  };

  updateProjectTags = async (projectId: string, tags: string[]) => {
    const response = await this.createAxios().post(
      `/projects/${projectId}/tags`,
      tags
    );

    return response.data;
  };

  fetchProviderById = async (provider_id: string): Promise<Provider> => {
    const response = await this.createAxios().get<Provider>(
      `/providers/${provider_id}`
    );

    return response.data;
  };

  fetchProviderTags = async (provider_id: string): Promise<AdminTag[]> => {
    const response = await this.createAxios().get<AdminTag[]>(
      `/providers/${provider_id}/tags`
    );

    return response.data;
  };

  updateProviderTags = async (
    providerId: string,
    tags: ProviderTagRequest[]
  ) => {
    const response = await this.createAxios().post(
      `/v2/providers/${providerId}/tags`,
      tags
    );

    return response.data;
  };

  fetchProviderActiveMatches = async (providerId: string): Promise<Match[]> => {
    const response = await this.createAxios().get<Match[]>(
      `/providers/${providerId}/matches/active`
    );

    return response.data;
  };

  fetchProviderRefusedMatches = async (
    providerId: string
  ): Promise<Match[]> => {
    const response = await this.createAxios().get<Match[]>(
      `/providers/${providerId}/matches/refused`
    );

    return response.data;
  };

  fetchProviderPurchasedMatches = async (
    providerId: string
  ): Promise<Match[]> => {
    const response = await this.createAxios().get<Match[]>(
      `/providers/${providerId}/matches/purchased`
    );

    return response.data;
  };

  fetchProviderMatches = async (providerId: string): Promise<Match[]> => {
    const response = await this.createAxios().get<Match[]>(
      `/providers/${providerId}/matches`
    );

    return response.data;
  };

  fetchProviderReferredProjects = async (
    providerId: string
  ): Promise<Project[]> => {
    const response = await this.createAxios().get<Project[]>(
      `/providers/${providerId}/referrals/projects`
    );

    return response.data;
  };

  createBasicProvider = async (provider: Provider): Promise<Provider> => {
    const response = await this.createAxios().post<Provider>(
      "/providers/basic",
      provider
    );

    return response.data;
  };

  queryProviderPortfolios = async (
    page: number,
    limit: number
  ): Promise<Portfolio[]> => {
    const response = await this.createAxios().get<Portfolio[]>(
      `/providers/portfolios?page=${page}&limit=${limit}`
    );

    return response.data;
  };

  updateProviderPortfolioCuratedAt = async (
    portfolioId: string,
    request: { curated: boolean }
  ): Promise<Portfolio> => {
    const response = await this.createAxios().post<Portfolio>(
      `/providers/portfolios/${portfolioId}/curate`,
      request
    );

    return response.data;
  };

  fetchProviderPortfolios = async (
    providerId: string
  ): Promise<Portfolio[]> => {
    const response = await this.createAxios().get<Portfolio[]>(
      `/providers/${providerId}/portfolios`
    );

    return response.data;
  };

  uploadPortfolioAsset = async (
    data: FormData,
    providerId: string
  ): Promise<PortfolioAsset> => {
    const response = await this.createAxios().post(
      `/providers/${providerId}/portfolios/assets`,
      data
    );

    return response.data;
  };

  removePortfolioAsset = async (assetId: string, providerId: string) => {
    const response = await this.createAxios().delete(
      `/providers/${providerId}/portfolios/assets/${assetId}`
    );

    return response.data;
  };

  createPortfolio = async (
    data: FormData,
    providerId: string
  ): Promise<Portfolio> => {
    const response = await this.createAxios("multipart/form-data").post(
      `/providers/${providerId}/portfolios`,
      data
    );

    return response.data;
  };

  updatePortfolio = async (data: FormData, providerId: string) => {
    const response = await this.createAxios().put(
      `/providers/${providerId}/portfolios`,
      data
    );

    return response.data;
  };

  deletePortfolio = async (portfolio_id: string): Promise<Portfolio[]> => {
    const response = await this.createAxios().delete(
      `/providers/portfolios/${portfolio_id}`
    );

    return response.data;
  };

  fetchTags = async (): Promise<Tag[]> => {
    const response = await this.createAxios().get("/tags");

    return response.data;
  };

  fetchIndustries = async (locale: string): Promise<Industry[]> => {
    const response = await this.createAxios().get(
      `/tags/industries?locale=${locale}`
    );

    return response.data;
  };

  updateProject = async (request: AdminProjectRequest): Promise<Project> => {
    const response = await this.createAxios().put<Project>(
      `/projects`,
      request
    );

    return response.data;
  };

  fetchProjectDescriptionTemplateByLanguage = async (
    language: string
  ): Promise<ProjectDescriptionTemplate> => {
    const response = await this.createAxios().get<ProjectDescriptionTemplate>(
      "/projectDescriptionTemplate/" + language
    );
    return response.data;
  };

  updateProjectDescriptionTemplate = async (
    request: ProjectDescriptionTemplate
  ): Promise<ProjectDescriptionTemplate> => {
    const response = await this.createAxios().put<ProjectDescriptionTemplate>(
      `/projectDescriptionTemplate`,
      request
    );

    return response.data;
  };

  updateProvider = async (request: ProviderRequest): Promise<Provider> => {
    const response = await this.createAxios().put<Provider>(
      `/v2/providers`,
      request
    );

    return response.data;
  };

  updateTeamMemberOrder = async (
    teamMembers: TeamMemberItem[]
  ): Promise<TeamMember[]> => {
    const response = await this.createAxios().put(
      "/providers/team/members/order",
      teamMembers
    );

    return response.data;
  };

  raiseHand = async (params: RaiseHandParams): Promise<string> => {
    const response = await this.createAxios().put(
      `/matchmaking/raiseHand`,
      params
    );

    return response.data;
  };

  ignoreProject = async (
    matchId: string,
    refuseReason: string,
    refuseReasonSpecified: string
  ): Promise<Match> => {
    const response = await this.createAxios().put(
      `/matchmaking/${matchId}/ignore`,
      { refuseReason, refuseReasonSpecified }
    );

    return response.data;
  };
  unignoreProject = async (matchId: string): Promise<Match> => {
    const response = await this.createAxios().put(
      `/matchmaking/${matchId}/unignore`
    );

    return response.data;
  };

  adminRefuseMatch = async (
    matchId: string,
    refuseReason: string,
    refuseReasonSpecified: string
  ): Promise<Match> => {
    const response = await this.createAxios().put(
      `/matchmaking/${matchId}/refuse`,
      {
        refused_reason: refuseReason,
        refused_reason_specified: refuseReasonSpecified,
      }
    );

    return response.data;
  };
  adminUnrefuseMatch = async (matchId: string): Promise<Match> => {
    const response = await this.createAxios().put(
      `/matchmaking/${matchId}/unrefuse`
    );

    return response.data;
  };

  shortlistProvider = async (params: ShortlistParams): Promise<string> => {
    const response = await this.createAxios().put(
      `/matchmaking/shortlist`,
      params
    );

    return response.data;
  };

  referralCreditProvider = async (
    projectId: string,
    params: ReferralCreditParams
  ): Promise<Project> => {
    const response = await this.createAxios().put(
      `/projects/${projectId}/credits/referral`,
      params
    );

    return response.data;
  };

  fetchCompany = async (companyId: string): Promise<Company> => {
    const response = await this.createAxios().get(`/companies/${companyId}`);

    return response.data;
  };

  fetchCompanyQuickInfos = async (
    companyId: string
  ): Promise<CompanyQuickInfos> => {
    const response = await this.createAxios().get(
      `/companies/${companyId}/quick-info`
    );

    return response.data;
  };

  queryContacts = async (
    page: number,
    limit: number,
    query: string,
    includeHubspot?: boolean
  ): Promise<Contact[]> => {
    const response = includeHubspot
      ? await this.createAxios().get(
          "/v2/contacts/search?page=" +
            page +
            "&limit=" +
            limit +
            "&query=" +
            query +
            "&includeHubspot=true"
        )
      : await this.createAxios().get(
          "/v2/contacts/search?page=" +
            page +
            "&limit=" +
            limit +
            "&query=" +
            query
        );

    return response.data;
  };

  queryCompanyContacts = async (
    companyId: string,
    page: number,
    limit: number,
    query: string
  ): Promise<Contact[]> => {
    const response = await this.createAxios().get(
      `/companies/${companyId}/contacts/search?page=${page}&limit=${limit}&query=${query}`
    );

    return response.data;
  };

  queryCompanies = async (
    page: number,
    limit: number,
    query: string
  ): Promise<Company[]> => {
    const response = await this.createAxios().get(
      "/v2/companies/search?page=" +
        page +
        "&limit=" +
        limit +
        "&query=" +
        query
    );

    return response.data;
  };

  reviewProvider = async (review: ReviewRequest): Promise<Review> => {
    const response = await this.createAxios().post("/reviews", review);

    return response.data;
  };

  fetchReviews = async (
    page: number,
    limit: number
  ): Promise<ReviewResult[]> => {
    const response = await this.createAxios().get(
      "/reviews?page=" + page + "&limit=" + limit
    );

    return response.data;
  };

  searchGrants = async (
    page: number,
    limit: number,
    query: string,
    locale: string
  ): Promise<GrantResult[]> => {
    const response = await this.createAxios().get(
      `/grants/search?page=${page}&limit=${limit}&locale=${locale}&query=${query}`
    );

    return response.data;
  };

  queryFundingExplorer = async ({
    locale,
    page,
    limit,
    financingType,
    industry,
    status,
    subindustry,
    region,
    service,
    textQuery,
    selectedProjects,
    companyId,
    companySize,
    closingDate,
    filterBy,
    annualRevenue,
    tagActivities,
    tagActivityTypes,
    tagEligibleExpenses,
    tagSectors,
  }: AdminQueryFundingExplorerProps): Promise<GrantResult[]> => {
    let url = `/grants/fundingExplorer?locale=${locale}&page=${page}&limit=${limit}`;
    const financingTypeString = financingType?.toString();
    const industryString = industry?.toString();
    const subindustryString = subindustry?.toString();
    const statusString = status?.toString();
    const regionString = region?.toString();
    const serviceString = service?.toString();
    const tagSectorsString = tagSectors?.toString();
    const tagActivityTypesString = tagActivityTypes?.toString();
    const tagActivitiesString = tagActivities?.toString();
    const tagEligibleExpensesString = tagEligibleExpenses?.toString();
    const selectedProjectsString = selectedProjects?.toString();

    if (textQuery !== undefined) {
      url += `&textQuery=${textQuery}`;
    }

    if (companyId !== undefined) {
      url += `&companyId=${companyId}`;
    }

    if (filterBy !== undefined) {
      url += `&filterBy=${filterBy}`;
    }

    if (industry !== undefined && industry!.length > 0) {
      url += `&industry=${industryString}`;
    }

    if (subindustry !== undefined && subindustry!.length > 0) {
      url += `&subindustry=${subindustryString}`;
    }

    if (financingType !== undefined && financingType!.length > 0) {
      url += `&financingType=${financingTypeString}`;
    }

    if (status !== undefined && status!.length > 0) {
      url += `&status=${statusString}`;
    }

    if (region !== undefined && region!.length > 0) {
      url += `&region=${regionString}`;
    }

    if (closingDate !== undefined) {
      url += `&closingDate=${closingDate.valueOf()}`;
    }

    if (service !== undefined && service!.length > 0) {
      url += `&service=${serviceString}`;
    }

    if (tagSectors !== undefined && tagSectors!.length > 0) {
      url += `&tagSectors=${tagSectorsString}`;
    }

    if (tagActivityTypes !== undefined && tagActivityTypes!.length > 0) {
      url += `&tagActivityTypes=${tagActivityTypesString}`;
    }

    if (tagActivities !== undefined && tagActivities!.length > 0) {
      url += `&tagActivities=${tagActivitiesString}`;
    }

    if (tagEligibleExpenses !== undefined && tagEligibleExpenses!.length > 0) {
      url += `&tagEligibleExpenses=${tagEligibleExpensesString}`;
    }

    if (selectedProjects !== undefined && selectedProjects!.length > 0) {
      url += `&selectedProjects=${selectedProjectsString}`;
    }

    if (annualRevenue !== undefined && annualRevenue !== "") {
      url += `&annualRevenue=${annualRevenue}`;
    }

    if (companySize !== undefined && companySize !== "") {
      url += `&companySize=${companySize}`;
    }

    const response = await this.createAxios().get(url);
    return response.data;
  };

  queryGrants = async (
    locale: string,
    page: number,
    limit: number,
    amountNeeded?: number,
    numberEmployee?: number,
    financingType?: string[],
    industry?: string[],
    service?: string[],
    grantProvider?: string[],
    status?: string[],
    textQuery?: string,
    filterBy?: string
  ): Promise<GrantResult[]> => {
    let url = `/grants?locale=${locale}&page=${page}&limit=${limit}`;
    const financingTypeString = financingType?.toString();
    const industryString = industry?.toString();
    const serviceString = service?.toString();
    const grantProviderString = grantProvider?.toString();

    if (amountNeeded !== undefined && amountNeeded !== null) {
      url += `&amountNeeded=${amountNeeded}`;
    }

    if (numberEmployee !== undefined && numberEmployee !== null) {
      url += `&numberEmployee=${numberEmployee}`;
    }

    if (financingType !== undefined && financingType!.length > 0) {
      url += `&financingType=${financingTypeString}`;
    }

    if (industry !== undefined && industry!.length > 0) {
      url += `&industry=${industryString}`;
    }

    if (service !== undefined && service!.length > 0) {
      url += `&service=${serviceString}`;
    }

    if (grantProvider !== undefined && grantProvider!.length > 0) {
      url += `&grantProvider=${grantProviderString}`;
    }

    if (status !== undefined && status!.length > 0) {
      url += `&status=${status}`;
    }

    if (textQuery !== undefined && textQuery !== null && textQuery !== "") {
      url += `&query=${textQuery}`;
    }

    if (filterBy !== undefined) {
      url += `&filterBy=${filterBy}`;
    }

    const response = await this.createAxios().get(url);
    return response.data;
  };

  queryGrantProviders = async (
    locale: string,
    page: number,
    limit: number,
    textQuery?: string
  ): Promise<GrantProvider[]> => {
    let url = `/grants/providers?locale=${locale}&page=${page}&limit=${limit}`;

    if (textQuery !== undefined && textQuery !== null && textQuery !== "") {
      url += `&query=${textQuery}`;
    }

    const response = await this.createAxios().get(url);
    return response.data;
  };

  fetchGrantById = async (grant_id: string): Promise<AdminGrantResult> => {
    const response = await this.createAxios().get<AdminGrantResult>(
      `/grants/admin/${grant_id}`
    );
    return response.data;
  };

  fetchGrantProviderById = async (
    grant_provider_id: string
  ): Promise<GrantProvider[]> => {
    const response = await this.createAxios().get<GrantProvider[]>(
      `/grants/providers/${grant_provider_id}`
    );

    return response.data;
  };

  createGrantContentRaw = async (
    grant_id: string,
    locale: string
  ): Promise<GrantContentRaw> => {
    const response = await this.createAxios().get<GrantContentRaw>(
      `/grants/context/scrape/${grant_id}?locale=${locale}`
    );

    return response.data;
  };

  fetchGrantContentRaw = async (
    grant_id: string,
    locale: string
  ): Promise<GrantContentRaw> => {
    const response = await this.createAxios().get<GrantContentRaw>(
      `/grants/context/${grant_id}?locale=${locale}`
    );

    return response.data;
  };

  fetchGrantFinancingType = async (
    locale: string
  ): Promise<GrantFinancingType[]> => {
    const response = await this.createAxios().get<GrantFinancingType[]>(
      `grants/financingType?locale=${locale}`
    );
    return response.data;
  };

  fetchGrantService = async (locale: string): Promise<GrantService[]> => {
    const response = await this.createAxios().get<GrantService[]>(
      `grants/service?locale=${locale}`
    );
    return response.data;
  };

  fetchGrantForProfit = async (locale: string): Promise<GrantForProfit[]> => {
    const response = await this.createAxios().get<GrantForProfit[]>(
      `grants/forProfit?locale=${locale}`
    );
    return response.data;
  };

  fetchProvinces = async (locale: string): Promise<Province[]> => {
    const response = await this.createAxios().get<Province[]>(
      `grants/province?locale=${locale}`
    );
    return response.data;
  };

  fetchGrantProvidersByGrantId = async (
    locale: string,
    grantId: string
  ): Promise<GrantProvider[]> => {
    const response = await this.createAxios().get<GrantProvider[]>(
      `grants/providers/grant/${grantId}?locale=${locale}`
    );
    return response.data;
  };

  fetchGrantProvidedByProvider = async (
    grantProviderId: string
  ): Promise<GrantPreview[]> => {
    const response = await this.createAxios().get<GrantPreview[]>(
      `grants/providers/grants/${grantProviderId}`
    );
    return response.data;
  };

  createGrant = async (
    request: AdminGrantResult
  ): Promise<AdminSimilarGrantResult> => {
    const response = await this.createAxios().post<AdminSimilarGrantResult>(
      `/grants`,
      request
    );

    return response.data;
  };

  createScrape = async (grantID: string): Promise<void> => {
    await this.createAxios().post(`/grants/scrape/${grantID}`);
  };

  deleteGrant = async (grantID: string): Promise<string> => {
    const response = await this.createAxios().delete<string>(
      `/grants/${grantID}`
    );

    return response.data;
  };

  deleteGrantTimeline = async (grantTimelineID: string): Promise<string> => {
    const response = await this.createAxios().delete<string>(
      `/grants/timeline/${grantTimelineID}`
    );

    return response.data;
  };

  deleteGrantProjects = async (
    grantProjectIds: string[]
  ): Promise<string[]> => {
    const response = await this.createAxios().post<string[]>(
      `/grants/grant-projects/delete`,
      grantProjectIds
    );

    return response.data;
  };

  fetchSimilarGrants = async (grantId: string): Promise<string[]> => {
    const response = await this.createAxios().get<string[]>(
      `/grants/similarIds/${grantId}`
    );

    return response.data;
  };

  updateGrant = async (
    request: AdminGrantResult
  ): Promise<AdminGrantResult> => {
    const response = await this.createAxios().put<AdminGrantResult>(
      `/grants`,
      request
    );

    return response.data;
  };

  updateGrantPromoted = async (
    grant_id: string,
    promoted: boolean
  ): Promise<string> => {
    const response = await this.createAxios().put(`/grants/promoted`, {
      grant_id,
      promoted,
    });

    return response.data;
  };

  updateGrantContentRaw = async (
    request: GrantContentRaw
  ): Promise<GrantContentRaw> => {
    const response = await this.createAxios().put<GrantContentRaw>(
      `/grants/context`,
      request
    );

    return response.data;
  };

  generateSingleGrantContentInfo = async (
    grantId: string,
    sectionName: string,
    industrySectorsIDs?: string[]
  ): Promise<string> => {
    const url =
      industrySectorsIDs !== null && industrySectorsIDs !== undefined
        ? `/grants/grant-content-info/generate/single/${grantId}?section=${sectionName}&industrySectorsIDs=${industrySectorsIDs}`
        : `/grants/grant-content-info/generate/single/${grantId}?section=${sectionName}`;
    const response = await this.createAxios().get<string>(url);

    return response.data;
  };

  generateAllGrantProjects = async (
    grantId: string,
    grantProjects: GrantProject[]
  ): Promise<GrantProject[]> => {
    const response = await this.createAxios().put<GrantProject[]>(
      `/grants/grant-projects/generate/${grantId}`,
      grantProjects
    );
    return response.data;
  };

  generateGrantTimeline = async (grantId: string): Promise<GrantTimeline[]> => {
    const response = await this.createAxios().put(
      `/grants/grant-timeline/generate/${grantId}`
    );
    return response.data;
  };

  createGrantProvider = async (
    request: GrantProviders
  ): Promise<GrantProviders> => {
    const response = await this.createAxios().post<GrantProviders>(
      `/grants/providers`,
      request
    );

    return response.data;
  };

  updateGrantProvider = async (
    request: GrantProviders
  ): Promise<GrantProviders> => {
    const response = await this.createAxios().put<GrantProviders>(
      `/grants/providers`,
      request
    );

    return response.data;
  };

  syncAllGrantList = async (): Promise<string> => {
    const response = await this.createAxios().get<string>(
      `grants/crm/syncAllGrants`
    );
    return response.data;
  };

  queryGrantApplications = async (
    page: number,
    limit: number,
    grantName?: string
  ): Promise<GrantApplication[]> => {
    let query = `/grantApplications?page=${page}&limit=${limit}`;

    if (grantName !== undefined) {
      query += `&grantName=${grantName}`;
    }

    const response = await this.createAxios().get(query);
    return response.data;
  };

  fetchReviewsById = async (providerId: string): Promise<ReviewResult[]> => {
    const response = await this.createAxios().get(`/reviews/${providerId}`);

    return response.data;
  };

  fetchMatchReview = async (
    providerID: string,
    projectID: string
  ): Promise<ReviewResult[]> => {
    const response = await this.createAxios().get(
      `/reviews/match/${providerID}/${projectID}`
    );
    return response.data;
  };

  getProjectById = async (projectId: string): Promise<Project> => {
    const response = await this.createAxios().get<Project>(
      "/projects/" + projectId
    );
    return response.data;
  };

  getProjectDescriptionByProjectId = async (
    projectId: string
  ): Promise<ProjectDescription> => {
    const response = await this.createAxios().get<ProjectDescription>(
      "/projectDescription/" + projectId
    );

    return response.data;
  };

  getReviewURL = async (
    providerId: string,
    projectId: string
  ): Promise<string> => {
    const response = await this.createAxios().get(
      `reviews/url/${providerId}/${projectId}`
    );
    return response.data;
  };

  fetchReview = async (projectId: string): Promise<ReviewNotification> => {
    const response = await this.createAxios().get(
      `/reviews/${projectId}/notification`
    );

    return response.data;
  };

  activateProject = async (projectId: string): Promise<Project> => {
    const response = await this.createAxios().put<Project>(
      "/projects/" + projectId + "/activate"
    );

    return response.data;
  };

  sendReviewNotification = async (
    projectId: string
  ): Promise<ReviewNotification> => {
    const response = await this.createAxios().post(
      `/reviews/${projectId}/notification/send`
    );
    return response.data;
  };

  sendProviderReviewNotification = async (matchId: string): Promise<string> => {
    const response = await this.createAxios().post(`/reviews/${matchId}/send`);
    return response.data;
  };

  createAuth0User = async (
    contactID: string,
    destination: string
  ): Promise<CreateAuth0UserResponse> => {
    const response = await this.createAxios().post(
      `/auth0/account/create/${contactID}`,
      { Destination: destination }
    );
    return response.data;
  };

  createAuth0Admin = async (
    contactID: string,
    destination: string
  ): Promise<CreateAuth0UserResponse> => {
    const response = await this.createAxios().post(
      `/admin/auth0/account/create/${contactID}`,
      { Destination: destination }
    );
    return response.data;
  };

  resetAuth0PasswordLink = async (
    contactID: string,
    destination: string
  ): Promise<string> => {
    const response = await this.createAxios().put<string>(
      `/auth0/account/resetPassword/link/${contactID}`,
      { Destination: destination }
    );
    return response.data;
  };

  resetAuth0PasswordEmail = async (
    contactID: string,
    destination: string
  ): Promise<string> => {
    const response = await this.createAxios().put<string>(
      `/auth0/account/resetPassword/email/${contactID}`,
      { Destination: destination }
    );
    return response.data;
  };
  resetAdminAuth0PasswordLink = async (
    contactID: string,
    destination: string
  ): Promise<string> => {
    const response = await this.createAxios().put<string>(
      `/admin/auth0/account/resetPassword/link/${contactID}`,
      { Destination: destination }
    );
    return response.data;
  };

  resetAdminAuth0PasswordEmail = async (
    contactID: string,
    destination: string
  ): Promise<string> => {
    const response = await this.createAxios().put<string>(
      `/admin/auth0/account/resetPassword/email/${contactID}`,
      { Destination: destination }
    );
    return response.data;
  };

  getAuth0UserID = async (
    contactEmail: string
  ): Promise<FindAuth0IDResponse> => {
    const response = await this.createAxios().get(
      `/auth0/account/${contactEmail}`
    );
    return response.data;
  };

  getPotentialMatches = async (
    projectId: string
  ): Promise<PotentialProviderMatch[]> => {
    const response = await this.createAxios().get(
      `/matchmaking/${projectId}/potentialMatches`
    );
    return response.data;
  };

  getDirectoryMatches = async (projectId: string): Promise<Match[]> => {
    const response = await this.createAxios().get(
      `/matchmaking/${projectId}/directoryMatches`
    );
    return response.data;
  };

  createBasicProject = async (project: BasicProjectForm): Promise<Project> => {
    const response = await this.createAxios().post<Project>(
      "/projects/basic",
      project
    );

    return response.data;
  };

  cancelProject = async (
    projectId: string,
    data: CancelProjectBody
  ): Promise<Project> => {
    const response = await this.createAxios().put<Project>(
      "/projects/" + projectId + "/cancel",
      data
    );

    return response.data;
  };

  deleteProject = async (projectId: string): Promise<Project> => {
    const response = await this.createAxios().delete<Project>(
      "/projects/" + projectId
    );

    return response.data;
  };

  completeProject = async (projectID: string) => {
    const response = await this.createAxios().put<Project>(
      "/projects/" + projectID + "/complete"
    );

    return response.data;
  };
  updateProjectStatus = async (projectID: string, status: ProjectStatus) => {
    const response = await this.createAxios().put<Project>(
      "/projects/" + projectID + "/status",
      status
    );

    return response.data;
  };

  updateCompanyV2 = async (
    company_id: string,
    data: FormData
  ): Promise<Company> => {
    const response = await this.createAxios("multipart/form-data").put<Company>(
      "/v2/companies/" + company_id,
      data
    );

    return response.data;
  };

  updateCompany = async (company: Company): Promise<Company> => {
    const response = await this.createAxios().put<Company>(
      "/companies/" + company.company_id,
      company
    );

    return response.data;
  };

  updateCompanyBillingId = async (
    companyId: string,
    billingId: string
  ): Promise<string> => {
    const response = await this.createAxios().post<string>(
      "/companies/" + companyId + "/billing/" + billingId,
      {}
    );

    return response.data;
  };

  deleteCompany = async (company_id: string): Promise<string> => {
    const response = await this.createAxios().delete<string>(
      "/companies/" + company_id
    );

    return response.data;
  };

  updateContact = async (contact: Contact): Promise<Contact> => {
    const response = await this.createAxios().put<Contact>(
      "/contacts/" + contact.contact_id,
      contact
    );

    return response.data;
  };

  deleteContact = async (contactID: string): Promise<string> => {
    const response = await this.createAxios().delete(`/contacts/${contactID}`);
    return response.data;
  };

  getProviderById = async (providerId: string): Promise<Provider> => {
    const response = await this.createAxios().get("/providers/" + providerId);

    return response.data;
  };

  getProjectAssets = async (projectId: string): Promise<ProjectAsset[]> => {
    const response = await this.createAxios().get<ProjectAsset[]>(
      "/projects/" + projectId + "/assets"
    );

    return response.data;
  };

  createProjectAsset = async (
    projectId: string,
    data: FormData
  ): Promise<ProjectAsset> => {
    const response = await this.createAxios(
      "multipart/form-data"
    ).post<ProjectAsset>("/projects/" + projectId + "/assets", data);

    return response.data;
  };

  deleteProjectAsset = async (assetId: string): Promise<string> => {
    const response = await this.createAxios().delete<string>(
      "/projects/assets/" + assetId
    );

    return response.data;
  };

  manualCharge = async (
    providerId: string,
    providerContactId: string,
    projectId: string,
    price: number,
    token?: Token
  ): Promise<Match> => {
    const data = {
      provider_id: providerId,
      provider_contact_id: providerContactId,
      project_id: projectId,
      price: price,
      token: token ?? null,
    };

    const response = await this.createAxios().post<Match>(
      "/matchmaking/charge",
      data
    );

    return response.data;
  };

  createBillingSession = async (billingID: string): Promise<BillingSession> => {
    const returnUrl = window?.location?.href || "";

    const response = await this.createAxios().get(
      `/billing/${billingID}/session?returnUrl=${returnUrl}`
    );

    return response.data;
  };

  globalSearch = async (
    query: string,
    locale: string
  ): Promise<SearchResults> => {
    const response = await this.createAxios().get(
      "/search?query=" + query + "&locale=" + locale
    );

    return response.data;
  };

  queryProviders = async (
    page: number,
    limit: number,
    query: string
  ): Promise<ProviderSearchResult[]> => {
    const response = await this.createAxios().get(
      "/providers?page=" + page + "&limit=" + limit + "&query=" + query
    );

    return response.data;
  };

  queryProjects = async (
    page: number,
    limit: number,
    query: string
  ): Promise<ProjectSearchResult[]> => {
    const response = await this.createAxios().get(
      "/projects?page=" + page + "&limit=" + limit + "&query=" + query
    );

    return response.data;
  };

  match = async (projectId: string): Promise<MatchmakingResponse> => {
    const response = await this.createAxios().post(
      "/matchmaking/projects/" + projectId
    );

    return response.data;
  };

  drip = async (manifestId: string): Promise<MatchmakingResponse> => {
    const response = await this.createAxios().post(
      "/matchmaking/" + manifestId + "/drip"
    );

    return response.data;
  };

  stopDrip = async (journeyId: string): Promise<MatchmakingResponse> => {
    const response = await this.createAxios().put(
      `/matchmaking/${journeyId}/drip/stop`
    );

    return response.data;
  };

  resumeDrip = async (journeyId: string): Promise<MatchmakingResponse> => {
    const response = await this.createAxios().put(
      `/matchmaking/${journeyId}/drip/resume`
    );

    return response.data;
  };

  notifyNow = async (journeyId: string): Promise<MatchmakingResponse> => {
    const response = await this.createAxios().put(
      `/matchmaking/${journeyId}/drip/notify`
    );

    return response.data;
  };

  notifyAll = async (journeyId: string, projectId: string): Promise<number> => {
    const data = {
      project_id: projectId,
    };
    const response = await this.createAxios().put(
      `/matchmaking/${journeyId}/notifyAll`,
      data
    );
    return response.data;
  };

  getMatchmakingManifestByProjectId = async (
    projectId: string
  ): Promise<MatchmakingResponse> => {
    const response = await this.createAxios().get(
      "/matchmaking/manifest/" + projectId
    );

    return response.data;
  };

  getMatchmakingNotificationsByJourneyID = async (
    journeyId: string
  ): Promise<MatchmakingNotification[]> => {
    const response = await this.createAxios().get<MatchmakingNotification[]>(
      "/notifications/journey/" + journeyId
    );
    return response.data;
  };

  updateWinner = async (matchId: string, isWinner: boolean): Promise<Match> => {
    const data = {
      is_winner: isWinner,
    };

    const response = await this.createAxios().put(
      "/matchmaking/" + matchId + "/winner",
      data
    );

    return response.data;
  };

  getAuth0Admins = async (): Promise<Admin[]> => {
    const response = await this.createAxios().get("/users");

    return response.data;
  };

  getAdmins = async (
    needsAuth0: boolean,
    page: number,
    limit: number,
    searchedTerm?: string
  ): Promise<AdminProfileResponse> => {
    const response = await this.createAxios().get(
      `/admins?needsAuth0=${needsAuth0}&page=${page}&limit=${limit}&search=${searchedTerm}`
    );

    return response.data;
  };

  refreshAdmins = async (): Promise<AdminProfileResponse> => {
    const response = await this.createAxios().get("/admins/refresh");

    return response.data;
  };

  fetchAdminById = async (adminId: string): Promise<AdminProfile> => {
    const response = await this.createAxios().get(`/admins/${adminId}`);

    return response.data;
  };

  getBlacklistedProviders = async (projectId: string): Promise<Provider[]> => {
    const response = await this.createAxios().get<Provider[]>(
      "/projects/" + projectId + "/providers/blacklist"
    );

    return response.data;
  };

  createBlacklistedProvider = async (
    projectId: string,
    providerId: string
  ): Promise<Provider> => {
    const data = {
      provider_id: providerId,
    };

    const response = await this.createAxios().post<Provider>(
      "/projects/" + projectId + "/providers/" + providerId + "/blacklist",
      data
    );

    return response.data;
  };

  deleteBlacklistedProvider = async (
    projectId: string,
    providerId: string
  ): Promise<string> => {
    const response = await this.createAxios().delete<string>(
      "/projects/" + projectId + "/providers/" + providerId + "/blacklist"
    );

    return response.data;
  };

  manualMatch = async (
    projectId: string,
    providerId: string
  ): Promise<string> => {
    const data = {
      provider_id: providerId,
    };

    const response = await this.createAxios().post<string>(
      "/matchmaking/projects/" + projectId + "/match",
      data
    );

    return response.data;
  };

  updateProviderStatus = async (
    providerId: string,
    status: ProviderStatus
  ): Promise<string> => {
    const data = {
      status: status,
    };

    const response = await this.createAxios().put<string>(
      "/providers/" + providerId + "/status",
      data
    );

    return response.data;
  };

  getProfileScore = async (providerId: string): Promise<ProviderProfile> => {
    const response = await this.createAxios().get(
      `/providers/${providerId}/profileScore`
    );

    return response.data;
  };

  getCreditBalance = async (billingId: string): Promise<number> => {
    const response = await this.createAxios().get(
      `/billing/${billingId}/balance`
    );

    return response.data;
  };

  discountProject = async (
    projectId: string,
    discountedAmount: number
  ): Promise<string> => {
    const data = {
      discounted_amount: discountedAmount,
    };

    const response = await this.createAxios().put<string>(
      `/projects/${projectId}/discount`,
      data
    );

    return response.data;
  };

  sendDiscountNotification = async (projectId: string): Promise<string> => {
    const response = await this.createAxios().post<string>(
      `/projects/${projectId}/discount/notification`
    );

    return response.data;
  };

  fetchInvoice = async (matchId: string): Promise<Invoice> => {
    const response = await this.createAxios().get(
      `/matchmaking/${matchId}/invoice`
    );

    return response.data;
  };

  fetchRaisehandInvoice = async (matchId: string): Promise<Invoice> => {
    const response = await this.createAxios().get(
      `/matchmaking/${matchId}/raisehandInvoice`
    );
    return response.data;
  };

  fetchWinFeeInvoice = async (matchId: string): Promise<Invoice> => {
    const response = await this.createAxios().get(
      `/matchmaking/${matchId}/winFeeInvoice`
    );
    return response.data;
  };

  chargeWinFee = async (params: WinFeeParams): Promise<string> => {
    const response = await this.createAxios().put(
      `/matchmaking/chargeWinFee`,
      params
    );

    return response.data;
  };
  fetchTeamMembers = async (providerId: string): Promise<TeamMember[]> => {
    const response = await this.createAxios().get<TeamMember[]>(
      `/providers/${providerId}/team/members`
    );

    return response.data;
  };
  deleteTeamMember = async (
    provider_team_member_id: string,
    providerId: string
  ): Promise<TeamMember> => {
    const response = await this.createAxios("multipart/form-data").delete(
      `/providers/${providerId}/team/members/${provider_team_member_id}`
    );
    return response.data;
  };
  submitTeamMember = async (
    data: FormData,
    providerId: string
  ): Promise<TeamMember> => {
    const response = await this.createAxios("multipart/form-data").post(
      `/providers/${providerId}/team/members`,
      data
    );
    return response.data;
  };

  editTeamMember = async (
    teamMemberId: string,
    providerId: string,
    data: FormData
  ): Promise<TeamMember> => {
    const response = await this.createAxios("multipart/form-data").put(
      `/providers/${providerId}/team/members/${teamMemberId}`,
      data
    );
    return response.data;
  };

  removeTeamMember = async (
    teamMemberID: string,
    providerId: string
  ): Promise<string> => {
    const response = await this.createAxios().delete(
      `/providers/${providerId}/team/members/${teamMemberID}`
    );
    return response.data;
  };

  updateLogo = async (companyId: string, data: FormData): Promise<Provider> => {
    const response = await this.createAxios(
      "multipart/form-data"
    ).put<Provider>(`/company/${companyId}/picture`, data);
    return response.data;
  };

  deleteLogo = async (companyId: string): Promise<Provider> => {
    const response = await this.createAxios().delete<Provider>(
      `/company/${companyId}/picture`
    );
    return response.data;
  };

  updateGrantLogo = async (
    grantId: string,
    data: FormData
  ): Promise<GrantResult> => {
    const response = await this.createAxios(
      "multipart/form-data"
    ).put<GrantResult>(`/grants/${grantId}/picture`, data);

    return response.data;
  };

  updateGrantProviderLogo = async (
    grantProviderId: string,
    locale: string,
    data: FormData
  ): Promise<GrantProvider> => {
    const response = await this.createAxios(
      "multipart/form-data"
    ).put<GrantProvider>(
      `/grants/providers/${grantProviderId}/picture?locale=${locale}`,
      data
    );
    return response.data;
  };

  updateBanner = async (
    companyId: string,
    data: FormData
  ): Promise<Provider> => {
    const response = await this.createAxios(
      "multipart/form-data"
    ).put<Provider>(`/company/${companyId}/banner`, data);
    return response.data;
  };

  deleteBanner = async (companyId: string): Promise<Provider> => {
    const response = await this.createAxios().delete<Provider>(
      `/company/${companyId}/banner`
    );
    return response.data;
  };

  updateVideos = async (
    companyId: string,
    data: FormData
  ): Promise<CompanyAsset> => {
    const response = await this.createAxios(
      "multipart/form-data"
    ).put<CompanyAsset>(`/company/${companyId}/videos`, data);
    return response.data;
  };

  deleteVideos = async (companyId: string): Promise<string> => {
    const response = await this.createAxios().delete<string>(
      `/company/${companyId}/videos`
    );
    return response.data;
  };

  updateReview = async (request: Review): Promise<Review> => {
    const response = await this.createAxios().put(`/reviews`, request);
    return response.data;
  };

  fetchPortfoliosById = async (providerId: string): Promise<Portfolio[]> => {
    const response = await this.createAxios().get<Portfolio[]>(
      `/providers/${providerId}/portfolios`
    );
    return response.data;
  };

  fetchProgramById = async (programId: string): Promise<Program> => {
    const response = await this.createAxios().get<Program>(
      `/programs/${programId}`
    );
    return response.data;
  };

  deleteProgram = async (programId: string): Promise<string> => {
    const response = await this.createAxios().delete<string>(
      `/programs/${programId}`
    );
    return response.data;
  };

  queryPrograms = async (page: number, limit: number): Promise<Program[]> => {
    const response = await this.createAxios().get<Program[]>(
      `/programs?page=${page}&limit=${limit}`
    );

    return response.data;
  };

  getCompanyPrograms = async (companyId: string): Promise<Program[]> => {
    const response = await this.createAxios().get<Program[]>(
      `/programs/companies/${companyId}`
    );

    return response.data;
  };

  createProgram = async (data: Program): Promise<Program> => {
    const response = await this.createAxios().post<Program>(`/programs`, data);

    return response.data;
  };

  updateProgram = async (data: Program): Promise<Program> => {
    const response = await this.createAxios().put<Program>(
      `/programs/${data.program_id}`,
      data
    );

    return response.data;
  };

  updateGinApplication = async (
    data: GinApplication
  ): Promise<GinApplication> => {
    const response = await this.createAxios().put<GinApplication>(
      `/gin/application`,
      data
    );

    return response.data;
  };

  createMilestone = async (milestone: Milestone): Promise<Milestone> => {
    const response = await this.createAxios().post<Milestone>(
      `/milestones/create`,
      milestone
    );

    return response.data;
  };

  updateMilestone = async (data: Milestone): Promise<Milestone> => {
    const response = await this.createAxios().post<Milestone>(
      `/milestones`,
      data
    );

    return response.data;
  };

  findMilestones = async (programId: string): Promise<Milestone[]> => {
    const response = await this.createAxios().get<Milestone[]>(
      `/milestones/${programId}`
    );

    return response.data;
  };

  createCompany = async (company: Company): Promise<Company> => {
    const response = await this.createAxios().post<Company>(
      `/companies/create`,
      company
    );

    return response.data;
  };

  createMilestoneAttachment = async (
    milestoneId: string,
    data: FormData
  ): Promise<MilestoneAttachment> => {
    const response = await this.createAxios(
      "multipart/form-data"
    ).post<MilestoneAttachment>(
      `/milestones/${milestoneId}/attachments/create`,
      data
    );
    return response.data;
  };

  fetchMilestonesAttachments = async (
    programId: string
  ): Promise<MilestoneAttachment[]> => {
    const response = await this.createAxios().get<MilestoneAttachment[]>(
      `/milestones/${programId}/attachments`
    );

    return response.data;
  };

  fetchCompanyContacts = async (companyId: string): Promise<Contact[]> => {
    const response = await this.createAxios().get<Contact[]>(
      `/companies/${companyId}/contacts`
    );

    return response.data;
  };

  fetchContact = async (contactID: string): Promise<Contact> => {
    const response = await this.createAxios().get<Contact>(
      `/contacts/${contactID}`
    );

    return response.data;
  };

  createContact = async (
    contact: Contact,
    destination: string
  ): Promise<Contact> => {
    const response = await this.createAxios().post<Contact>(
      `/contacts?destination=${destination}`,
      contact
    );

    return response.data;
  };

  assignContact = async (contact: Contact): Promise<Contact> => {
    const response = await this.createAxios().put<Contact>(
      `/assign/contacts`,
      contact
    );

    return response.data;
  };

  getContactByEmail = async (email: string): Promise<Contact> => {
    const response = await this.createAxios().get(`/contacts/email/${email}`);
    return response.data;
  };

  queryChats = async (
    page: number,
    limit: number,
    type: ChatType,
    grantName?: string
  ): Promise<HdChat[]> => {
    let query = `/chats?page=${page}&limit=${limit}&type=${type}`;

    if (grantName !== undefined) {
      query += `&grantName=${grantName}`;
    }

    const response = await this.createAxios().get(query);
    return response.data;
  };

  fetchChatById = async (chatId: string): Promise<HdChat> => {
    const response = await this.createAxios().get(`/chats/${chatId}`);
    return response.data;
  };

  fetchChatQuestions = async (
    chatId: string,
    locale: string
  ): Promise<HdChatQuestion[]> => {
    const response = await this.createAxios().get<HdChatQuestion[]>(
      `/chats/${chatId}/questions/${locale}`
    );
    return response.data;
  };

  createNewChat = async (chat: HdCreateChatRequest): Promise<HdChat> => {
    const payload = chat;
    const response = await this.createAxios().post<HdChat>(`/chats`, payload);
    return response.data;
  };

  selectChatCompany = async (
    chatId: string,
    companyId: string
  ): Promise<HdChat> => {
    const response = await this.createAxios().put(
      `/chats/${chatId}/company/${companyId}`
    );
    return response.data;
  };

  removeChatCompany = async (chatId: string): Promise<HdChat> => {
    const response = await this.createAxios().delete(
      `/chats/${chatId}/company`
    );
    return response.data;
  };

  selectChatTemplate = async (
    chatId: string,
    templateId: string,
    locale: string
  ): Promise<HdChat> => {
    const response = await this.createAxios().put(
      `/chats/${chatId}/templates/${templateId}/${locale}`
    );
    return response.data;
  };

  removeChatTemplate = async (chatId: string): Promise<HdChat> => {
    const response = await this.createAxios().delete(
      `/chats/${chatId}/templates`
    );
    return response.data;
  };

  updateChat = async (chat: HdChat): Promise<HdChat> => {
    const response = await this.createAxios().put(`/chats/${chat.id}`, chat);
    return response.data;
  };

  deleteChat = async (chatId: string): Promise<string> => {
    const response = await this.createAxios().delete<string>(
      `/chats/${chatId}`
    );
    return response.data;
  };

  createChatPrompt = async (
    prompt: HdCreateChatPromptRequest
  ): Promise<HdChatPromptRequest> => {
    const response = await this.createAxios().post<HdChatPromptRequest>(
      `/chats/prompts`,
      prompt
    );
    return response.data;
  };

  updateChatPrompt = async (
    prompt: HdChatPromptRequest
  ): Promise<HdChatPromptRequest> => {
    const response = await this.createAxios().put<HdChatPromptRequest>(
      `/chats/prompts/${prompt.id}`,
      prompt
    );
    return response.data;
  };

  deleteChatPrompt = async (promptId: string): Promise<string> => {
    const response = await this.createAxios().delete<string>(
      `/chats/prompts/${promptId}`
    );
    return response.data;
  };

  fetchAllPrompts = async (
    page: number,
    limit: number,
    type: ChatType
  ): Promise<{ prompts: HdChatPromptRequest[]; total: number }> => {
    const response = await this.createAxios().get(
      `/chats/prompts?page=${page}&limit=${limit}&type=${type}`
    );

    return response.data;
  };

  fetchAllPromptsForGrants = async (): Promise<HdChatPromptRequest[]> => {
    const response =
      await this.createAxios().get<HdChatPromptRequest[]>(`/prompts/grants`);
    return response.data;
  };

  generateQuestionAnswer = async (
    chatId: string,
    questionId: string,
    questionContext: string
  ): Promise<HdChatQuestion> => {
    const payload = {
      context: questionContext,
    };
    const response = await this.createAxios().post<HdChatQuestion>(
      `/chats/${chatId}/questions/${questionId}/generate`,
      payload
    );
    return response.data;
  };

  generateSubQuestionAnswer = async (
    chatId: string,
    questionId: string,
    subQuestion: HdChatSubQuestion
  ): Promise<{
    chatId: string;
    questionId: string;
    subQuestion: HdChatSubQuestion;
  }> => {
    const response = await this.createAxios().post<HdChatSubQuestion>(
      `/chats/${chatId}/questions/${questionId}/subQuestions/${subQuestion.id}/generate`,
      subQuestion
    );
    return {
      chatId: chatId,
      questionId: questionId,
      subQuestion: response.data,
    };
  };

  addQuestionToChat = async (
    chatId: string,
    promptId: string,
    locale: string
  ): Promise<HdChatQuestion> => {
    const response = await this.createAxios().post(
      `/chats/${chatId}/questions/${promptId}/${locale}`
    );
    return response.data;
  };

  removeQuestionFromChat = async (
    chatId: string,
    questionId: string
  ): Promise<{ chatId: string; questionId: string }> => {
    const response = await this.createAxios().delete(
      `/chats/questions/${questionId}`
    );

    return { chatId: chatId, questionId: response.data };
  };

  addSubQuestionToChat = async (
    questionId: string,
    subQuestion: HdChatSubQuestionRequest
  ): Promise<{
    chatId: string;
    questionId: string;
    subQuestion: HdChatSubQuestion;
  }> => {
    const response = await this.createAxios().post(
      `/chats/${subQuestion.chat_id}/subQuestions`,
      subQuestion
    );
    return {
      chatId: subQuestion.chat_id,
      questionId: questionId,
      subQuestion: response.data,
    };
  };

  removeSubQuestionFromChat = async (
    chatId: string,
    questionId: string,
    subQuestion: HdChatSubQuestion
  ): Promise<{
    chatId: string;
    questionId: string;
    answerId: string;
    subQuestionId: string;
  }> => {
    const response = await this.createAxios().delete(
      `/chats/subQuestions/${subQuestion.id}`
    );
    return {
      chatId: chatId,
      questionId: questionId,
      answerId: subQuestion.answer_id,
      subQuestionId: response.data,
    };
  };

  updateSubQuestion = async (
    chatId: string,
    questionId: string,
    subQuestion: HdChatSubQuestion
  ): Promise<{
    chatId: string;
    questionId: string;
    subQuestion: HdChatSubQuestion;
  }> => {
    const response = await this.createAxios().put(
      `/chats/${chatId}/subQuestions/${subQuestion.id}`,
      subQuestion
    );
    return {
      chatId: chatId,
      questionId: questionId,
      subQuestion: response.data,
    };
  };

  fetchAllTemplates = async (
    page: number,
    limit: number,
    type: ChatType
  ): Promise<{ templates: HdChatTemplate[]; total: number }> => {
    const response = await this.createAxios().get(
      `/chats/templates?page=${page}&limit=${limit}&type=${type}`
    );
    return response.data;
  };

  fetchTemplateById = async (templateId: string): Promise<HdChatTemplate> => {
    const response = await this.createAxios().get<HdChatTemplate>(
      `/chats/templates/${templateId}`
    );
    return response.data;
  };

  createTemplate = async (
    template: HdCreateChatTemplateRequest
  ): Promise<HdChatTemplate> => {
    const response = await this.createAxios().post<HdChatTemplate>(
      `/chats/templates`,
      template
    );
    return {
      ...response.data,
      title: template.title,
    };
  };

  updateTemplate = async (
    template: HdChatTemplate
  ): Promise<HdChatTemplate> => {
    const response = await this.createAxios().put<HdChatTemplate>(
      `/chats/templates/${template.id}`,
      template
    );
    return {
      ...response.data,
      title: template?.title || "",
    };
  };

  deleteTemplate = async (templateId: string): Promise<string> => {
    const response = await this.createAxios().delete<string>(
      `/chats/templates/${templateId}`
    );
    return response.data;
  };

  fetchTemplatePrompts = async (
    templateId: string,
    locale: string
  ): Promise<HdChatPrompt[]> => {
    const response = await this.createAxios().get<HdChatPrompt[]>(
      `/chats/templates/${templateId}/prompts/${locale}`
    );
    return response.data;
  };

  updateTemplatePrompts = async (
    templateId: string,
    prompts: HdChatPrompt[]
  ): Promise<HdChatPrompt[]> => {
    const response = await this.createAxios().put<HdChatPrompt[]>(
      `/chats/templates/${templateId}/prompts`,
      prompts
    );
    return response.data;
  };

  queryPrompts = async (
    page: number,
    limit: number,
    query: string,
    type: string
  ): Promise<HdChatPrompt[]> => {
    const response = await this.createAxios().get<HdChatPrompt[]>(
      `/prompts/search?page=${page}&limit=${limit}&type=${type}&query=${query}`
    );
    return response.data;
  };

  exportChat = async (
    chatId: string,
    answerIds: string[],
    exportType: string
  ): Promise<string> => {
    const payload = {
      answer_ids: answerIds,
    };

    const response = await this.createAxios().post(
      `/chats/${chatId}/export/${exportType}`,
      payload
    );
    return response.data;
  };

  fetchGrantApplication = async (id: string): Promise<GrantApplication> => {
    const response = await this.createAxios().get<GrantApplication>(
      `/grantApplications/${id}`
    );
    return response.data;
  };

  updateGrantApplication = async (
    id: string,
    content: string
  ): Promise<GrantApplication> => {
    const payload = {
      content: content,
    };
    const response = await this.createAxios().put<GrantApplication>(
      `/grantApplications/${id}`,
      payload
    );
    return response.data;
  };

  queryForms = async (page: number, limit: number): Promise<Forms[]> => {
    const response = await this.createAxios().get(
      `/forms?page=${page}&limit=${limit}`
    );
    return response.data;
  };

  updateAdmin = async (data: AdminProfile): Promise<AdminProfile> => {
    const response = await this.createAxios().post<AdminProfile>(
      `/admin/update`,
      data
    );

    return response.data;
  };
  fetchFormById = async (formId: string): Promise<AdminFormResponse> => {
    const response = await this.createAxios().get(`/forms/${formId}`);

    return response.data;
  };

  fetchTranslatedFormByID = async (
    formId: string
  ): Promise<TranslatedFormResponse> => {
    const response = await this.createAxios().get(
      `/forms/translated/${formId}`
    );

    return response.data;
  };

  fetchAllFormSubmission = async (): Promise<AdminFormsSubmission[]> => {
    const response = await this.createAxios().get(`/forms/submission`);
    return response.data;
  };

  fetchAllFormSubmissionByCompanyId = async (
    companyId: string
  ): Promise<AdminFormsSubmission[]> => {
    const response = await this.createAxios().get(
      `/forms/submission/company/${companyId}`
    );

    return response.data;
  };

  fetchAllFormSubmissionByGrantId = async (
    grantId: string
  ): Promise<AdminFormsSubmission[]> => {
    const response = await this.createAxios().get(
      `/forms/submission/grant/${grantId}`
    );

    return response.data;
  };
  fetchAllFormSubmissionByFormId = async (
    formId: string
  ): Promise<AdminFormsSubmission[]> => {
    const response = await this.createAxios().get(
      `/forms/submission/form/${formId}`
    );

    return response.data;
  };

  fetchSingleFormSubmission = async (
    formSubmissionId: string
  ): Promise<AdminFormSubmission> => {
    const response = await this.createAxios().get(
      `/forms/submission/${formSubmissionId}`
    );

    return response.data;
  };
  fetchSingleFormSubmissionEdit = async (
    formSubmissionId: string
  ): Promise<EditableFormSubmissionResponse> => {
    const response = await this.createAxios().get(
      `/forms/submission/${formSubmissionId}/edit`
    );

    return response.data;
  };

  verifyAdmin = async (adminId: string): Promise<AdminProfile> => {
    const response = await this.createAxios().post<AdminProfile>(
      `/admin/verify/${adminId}`
    );

    return response.data;
  };

  editAdmin = async (data: AdminProfile): Promise<AdminProfile> => {
    const response = await this.createAxios().post<AdminProfile>(
      `/admin`,
      data
    );

    return response.data;
  };

  deleteAdmin = async (data: AdminProfile): Promise<string> => {
    const response = await this.createAxios().post<string>(
      `/admin/delete`,
      data
    );

    return response.data;
  };

  me = async (): Promise<AdminProfile> => {
    const response = await this.createAxios().get(`/me`);
    return response.data;
  };
  fetchFormItemSelectOptions = async (
    formItemId: string
  ): Promise<FormsSelectOptions[]> => {
    const response = await this.createAxios().get(`/forms/item/${formItemId}`);
    return response.data;
  };

  createForm = async (form: Forms): Promise<AdminFormResponse> => {
    const response = await this.createAxios().post("/forms", form);
    return response.data;
  };

  createFormItem = async (
    formItemTotal: FormsItemsTotal
  ): Promise<FormsItemsTotal> => {
    const response = await this.createAxios().post(
      "/forms/item",
      formItemTotal
    );
    return response.data;
  };

  createFormGroup = async (
    formGroup: FormsGroup
  ): Promise<FormGroupResponse> => {
    const response = await this.createAxios().post("/forms/group", formGroup);
    return response.data;
  };

  createFormPage = async (formPage: FormsPage): Promise<FormPageResponse> => {
    const response = await this.createAxios().post("/forms/page", formPage);
    return response.data;
  };

  createFormSelectOptions = async (
    items: FormsSelectOptions[]
  ): Promise<FormsSelectOptions[]> => {
    const response = await this.createAxios().post(
      `/forms/selectOptions`,
      items
    );
    return response.data;
  };

  updateForm = async (form: Forms): Promise<AdminFormResponse> => {
    const response = await this.createAxios().put(`/forms`, form);
    return response.data;
  };

  getHubspotFormId = async (
    formId: string
  ): Promise<AdminHubspotFormResponse> => {
    const response = await this.createAxios().get(`/forms/${formId}/crm`);
    return response.data;
  };

  updateHubspotForm = async (
    formId: string
  ): Promise<AdminHubspotFormResponse> => {
    const response = await this.createAxios().put(`/forms/${formId}/crm`);
    return response.data;
  };

  updateFormPage = async (formPage: FormsPage): Promise<FormPageResponse> => {
    const response = await this.createAxios().put("/forms/page", formPage);
    return response.data;
  };

  updateFormItem = async (
    formItem: FormItemResponse
  ): Promise<FormItemResponse> => {
    const response = await this.createAxios().put("/forms/item", formItem);
    return response.data;
  };

  updateFormPages = async (
    formResponse: AdminFormResponse
  ): Promise<AdminFormResponse> => {
    const response = await this.createAxios().put("/forms/pages", formResponse);
    return response.data;
  };

  updateFormGroup = async (
    formGroup: FormGroupResponse
  ): Promise<FormGroupResponse> => {
    const response = await this.createAxios().put("/forms/group", formGroup);
    return response.data;
  };

  updateFormGroups = async (
    formPage: FormPageResponse
  ): Promise<FormPageResponse> => {
    const response = await this.createAxios().put("/forms/groups", formPage);
    return response.data;
  };

  updateFormSelectOptions = async (
    formSelectOption: FormsSelectOptions[]
  ): Promise<FormsSelectOptions[]> => {
    const response = await this.createAxios().put(
      "/forms/item/selectOptions",
      formSelectOption
    );
    return response.data;
  };

  updateFormCondition = async (
    formCondition: FormsConditions
  ): Promise<FormsConditions> => {
    const response = await this.createAxios().put(
      "/forms/condition",
      formCondition
    );
    return response.data;
  };

  createFormCondition = async (
    formCondition: FormsConditions
  ): Promise<FormsConditions> => {
    const response = await this.createAxios().post(
      "/forms/condition",
      formCondition
    );
    return response.data;
  };

  updateFormItemOrder = async (
    formItemOrder: FormsGroupsItem[]
  ): Promise<FormsGroupsItem[]> => {
    const response = await this.createAxios().put(
      "/forms/item/formItemOrder",
      JSON.stringify(formItemOrder)
    );
    return response.data;
  };

  createFormSubmissionItem = async (
    formSubmissionItem: FormsSubmissionItem
  ): Promise<FormsSubmissionItem> => {
    const response = await this.createAxios().post(
      "/forms/submissionItem",
      JSON.stringify(formSubmissionItem)
    );
    return response.data;
  };

  transferFormGroup = async (
    transferFormGroup: TransferFromGroup
  ): Promise<TransferFromGroup> => {
    const response = await this.createAxios().put(
      "/forms/group/transfer",
      transferFormGroup
    );
    return response.data;
  };

  deleteFormPage = async (formPageId: string): Promise<string> => {
    const response = await this.createAxios().delete(
      `/forms/page/${formPageId}`
    );
    return response.data;
  };

  deleteFormGroup = async (formGroupId: string): Promise<string> => {
    const response = await this.createAxios().delete(
      `/forms/group/${formGroupId}`
    );
    return response.data;
  };

  deleteFormItem = async (formItemId: string): Promise<void> => {
    const response = await this.createAxios().delete(
      `/forms/item/${formItemId}`
    );
    return response.data;
  };

  deleteFormSelectOptions = async (
    formSelectOptionIds: string[]
  ): Promise<void> => {
    const response = await this.createAxios().put(
      `/forms/item/deleteSelectOptions`,
      formSelectOptionIds
    );
    return response.data;
  };

  deleteFormPublishedDate = async (formId: string): Promise<void> => {
    const response = await this.createAxios().delete(
      `/forms/publishedAt/${formId}`
    );
    return response.data;
  };

  searchAllTags = async (
    query: string,
    locale: string,
    limit: number
  ): Promise<NewTag[]> => {
    const response = await this.createAxios().get<NewTag[]>(
      "/grants/tags/search/all",
      {
        params: { query, locale, limit },
      }
    );
    return response.data;
  };

  addGrantTags = async (
    grantId: string,
    tagIds: string[]
  ): Promise<GrantTag[]> => {
    const payload = {
      tagIds: tagIds,
    };
    const response = await this.createAxios().post(
      `/grants/${grantId}/tags`,
      payload
    );

    return response.data;
  };

  deleteGrantTagWithChildren = async (
    grantId: string,
    tagId: string
  ): Promise<GrantTag[]> => {
    const response = await this.createAxios().delete(
      `/grants/${grantId}/tags/${tagId}`
    );

    return response.data;
  };

  addGrantProjectTags = async (
    grantProjectId: string,
    tagIds: string[]
  ): Promise<GrantProjectTag[]> => {
    const payload = {
      tagIds: tagIds,
    };
    const response = await this.createAxios().post(
      `/grant-projects/${grantProjectId}/tags`,
      payload
    );

    return response.data;
  };

  deleteGrantProjectTagWithChildren = async (
    grantProjectId: string,
    tagId: string
  ): Promise<GrantProjectTag[]> => {
    const response = await this.createAxios().delete(
      `/grant-projects/${grantProjectId}/tags/${tagId}`
    );

    return response.data;
  };

  generateGrantTags = async (grantId: string): Promise<GrantTag[]> => {
    const response = await this.createAxios().post(
      `/grants/tags/generate/${grantId}`
    );

    return response.data;
  };

  fetchGrantTags = async (
    grantId: string,
    locale: string
  ): Promise<GrantTag[]> => {
    const response = await this.createAxios().get<GrantTag[]>(
      `/grants/${grantId}/tags?locale=${locale}`
    );

    return response.data;
  };

  generateGrantProjectTags = async (
    grantProject: GrantProject
  ): Promise<GrantProjectTag[]> => {
    const response = await this.createAxios().post<GrantProjectTag[]>(
      `/grant-projects/tags/generate`,
      grantProject
    );
    return response.data;
  };

  fetchTagsByParentIds = async (
    parentIds: string[],
    type: TagType,
    locale: string
  ): Promise<{
    tags: NewTag[];
    type: TagType;
  }> => {
    const response = await this.createAxios().get<NewTag[]>(
      `/grants/tags/children?parentIds=${parentIds}&type=${type}&locale=${locale}`
    );

    return { tags: response.data, type: type };
  };

  fetchTagsByProjectId = async (
    grantProjectId: string,
    locale: string
  ): Promise<GrantProjectTag[]> => {
    const response = await this.createAxios().get<GrantProjectTag[]>(
      `/grant-projects/${grantProjectId}/tags?locale=${locale}`
    );
    return response.data;
  };

  deleteTagWithChildren = async (tagId: string): Promise<void> => {
    await this.createAxios().delete(`/grants/tags`, {
      data: { tagID: tagId },
    });
  };

  searchGrantsWithAI = async (
    query: string,
    locale: string,
    grantFilters: any,
    gptFilters: any
  ): Promise<GrantsVectorSearchResponse> => {
    const rephrase = gptFilters.rephrase === false ? gptFilters.rephrase : true;
    const rerank = gptFilters.rerank === true ? gptFilters.rerank : false;

    const requestBody = {
      query: query,
      filterBy:
        grantFilters.filterBy !== undefined ? grantFilters.filterBy : "",
      industry:
        grantFilters.industry !== undefined && grantFilters.industry!.length > 0
          ? grantFilters.industry?.toString()
          : "",
      subindustry:
        grantFilters.subindustry !== undefined &&
        grantFilters.subindustry!.length > 0
          ? grantFilters.subindustry?.toString()
          : "",
      status:
        grantFilters.status !== undefined && grantFilters.status!.length > 0
          ? grantFilters.status?.toString()
          : "",
      financingType:
        grantFilters.financingType !== undefined &&
        grantFilters.financingType!.length > 0
          ? grantFilters.financingType?.toString()
          : "",
      region:
        grantFilters.region !== undefined && grantFilters.region!.length > 0
          ? grantFilters.region?.toString()
          : "",
      service:
        grantFilters.service !== undefined && grantFilters.service!.length > 0
          ? grantFilters.service?.toString()
          : "",
      closingDate:
        grantFilters.closingDate !== undefined
          ? `${grantFilters.closingDate?.valueOf()}`
          : "",
      search_type: gptFilters.search_type || SearchType.HYBRID,
      hybrid_search_alpha: parseFloat(
        gptFilters.hybrid_search_alpha ? gptFilters.hybrid_search_alpha : 0.85
      ),
      hybrid_search_autocut: parseInt(
        gptFilters.hybrid_search_autocut ? gptFilters.hybrid_search_autocut : 3
      ),
      rephrase: rephrase,
      rephrase_max_tokens: parseInt(
        gptFilters.rephrase_max_tokens ? gptFilters.rephrase_max_tokens : 2000
      ),
      rephrase_temperature: parseFloat(
        gptFilters.rephrase_temperature ? gptFilters.rephrase_temperature : 0.7
      ),
      rephrase_model: gptFilters.rephrase_model
        ? gptFilters.rephrase_model
        : "gpt-4o-mini",
      extract_filters: rephrase
        ? gptFilters.extract_filters === false
          ? gptFilters.extract_filters
          : true
        : false,
      rerank: rerank,
      rerank_max_tokens: parseInt(
        gptFilters.rerank_max_tokens ? gptFilters.rerank_max_tokens : 2000
      ),
      rerank_temperature: parseFloat(
        gptFilters.rerank_temperature ? gptFilters.rerank_temperature : 0.7
      ),
      rerank_model: gptFilters.rerank_model
        ? gptFilters.rerank_model
        : "gpt-4o-mini",
    };

    const maxResultsString =
      gptFilters.max_results !== undefined ? gptFilters.max_results : 15;

    const response = await this.createAxios().post<GrantsVectorSearchResponse>(
      `/chats/assistant/grants-search?locale=${locale}&maxResults=${maxResultsString}`,
      requestBody
    );
    return response.data;
  };

  createFormSubmission = async (
    submission: AdminFormSubmissionResponse
  ): Promise<AdminFormSubmissionResponse> => {
    const response = await this.createAxios().post<AdminFormSubmissionResponse>(
      `/forms/submission`,
      submission
    );
    return response.data;
  };

  updateFormSubmission = async (
    submission: FormsSubmission
  ): Promise<FormsSubmission> => {
    const response = await this.createAxios().put<FormsSubmission>(
      `/forms/submission`,
      submission
    );
    return response.data;
  };

  createFormSubmissionItems = async (
    submissionItems: FormsSubmissionItem[]
  ): Promise<FormsSubmissionItem[]> => {
    const response = await this.createAxios().post<FormsSubmissionItem[]>(
      `/forms/submissionItem`,
      submissionItems
    );
    return response.data;
  };

  fetchPromptByName = async (name: string): Promise<HdChatPrompt> => {
    const response = await this.createAxios().get(`/chats/prompts/${name}`);

    return response.data;
  };

  getCompanyHubspotDeals = async (
    companyId: string
  ): Promise<DealHubspotResponse[]> => {
    const response = await this.createAxios().get<DealHubspotResponse[]>(
      `/programs/hubspot/${companyId}`
    );

    return response.data;
  };

  getCompanyCRMID = async (companyId: string): Promise<string> => {
    const response = await this.createAxios().get<string>(
      `/companies/${companyId}/crm`
    );

    return response.data;
  };

  createHubspotDeal = async (program: Program): Promise<Program> => {
    const response = await this.createAxios().post(
      `/programs/hubspot`,
      program
    );
    return response.data;
  };

  updateHubspotDeal = async (program: Program): Promise<Program> => {
    const response = await this.createAxios().put(`/programs/hubspot`, program);
    return response.data;
  };

  createProgramAndHubspotDeal = async (program: Program): Promise<Program> => {
    const response = await this.createAxios().post(`/programs/deal`, program);
    return response.data;
  };

  updateFormSubmissionEligibilityStatus = async (
    formSubmissionId: string,
    status: string
  ): Promise<AdminFormSubmission> => {
    const response = await this.createAxios().put(
      `/forms/submission/${formSubmissionId}/status`,
      status
    );
    return response.data;
  };

  createGinSection = async (
    grantId: string,
    section: string,
    content: string
  ): Promise<Gin> => {
    const response = await this.createAxios().post(`/gin/${grantId}`, {
      section,
      content,
    });

    return response.data;
  };

  getGinSection = async (grantId: string, section: string): Promise<Gin> => {
    const response = await this.createAxios().get(
      `gin/${grantId}?section=${section}`
    );

    return response.data;
  };

  fetchGinByGrantId = async (grantId: string): Promise<AdminGinResult> => {
    const response = await this.createAxios().get(`/gin/grant/${grantId}`);

    return response.data;
  };

  queryGinGrants = async (
    locale: string,
    query?: string
  ): Promise<AdminGrantResult[]> => {
    const url = `/gin?locale=${locale}${query ? `&query=${query}` : ""}`;
    const response = await this.createAxios().get(url);

    return response.data;
  };

  fetchGinApplications = async (grantId: string): Promise<GinProgram[]> => {
    const response = await this.createAxios().get(`/gin/${grantId}/programs`);

    return response.data;
  };

  fetchGinContacts = async (
    grantId: string
  ): Promise<AdminGinContactResponse[]> => {
    const response = await this.createAxios().get(`/gin/contact/${grantId}`);

    return response.data;
  };

  fetchGinContactsByGrantProviderId = async (
    grantProviderId: string
  ): Promise<AdminGinContactResponse[]> => {
    const response = await this.createAxios().get(
      `/gin/contact/grant-provider/${grantProviderId}`
    );

    return response.data;
  };

  updateGinContact = async (contact: GinContact): Promise<GinContact> => {
    const response = await this.createAxios().put(`/gin/contact`, contact);

    return response.data;
  };

  fetchRelatedGrants = async (
    grantId: string,
    locale: string
  ): Promise<GrantPreview[]> => {
    const response = await this.createAxios().get(
      `/grants/similar/${grantId}?locale=${locale}`
    );

    return response.data;
  };

  fetchGinApplication = async (
    applicationId: string
  ): Promise<GinApplication> => {
    const response = await this.createAxios().get(
      `/gin/application/${applicationId}`
    );

    return response.data;
  };

  updateSingleSelectedStep = async (step: Step): Promise<Step> => {
    const response = await this.createAxios().put("/gin/step", step);
    return response.data;
  };

  createNewStep = async (grantId: string, step: Step): Promise<Step> => {
    const response = await this.createAxios().post(
      `/gin/step/${grantId}`,
      step
    );
    return response.data;
  };

  createNewDocuments = async (
    document: DocumentNeeded
  ): Promise<DocumentNeeded> => {
    // To change when back end done
    const response = await this.createAxios().post(
      `/gin/document/${document.document_id}`,
      document
    );
    return response.data;
  };

  updateSingleSelectedDocument = async (
    document: DocumentNeeded
  ): Promise<DocumentNeeded> => {
    const response = await this.createAxios().put(
      `/gin/document/${document.document_id}`,
      document
    );
    return response.data;
  };

  deleteSingleSelectedStep = async (
    grant_id: string,
    step_id: string
  ): Promise<Step[]> => {
    const response = await this.createAxios().delete(
      `/gin/step/${grant_id}/${step_id}`
    );
    return response.data;
  };

  deleteSingleSelectedDocument = async (
    document_id: string
  ): Promise<string> => {
    const response = await this.createAxios().delete(
      `/gin/document/${document_id}`
    );
    return response.data;
  };

  updateStepsOrder = async (
    grantId: string,
    steps: Step[]
  ): Promise<Step[]> => {
    const response = await this.createAxios().put(
      `/gin/steps/order/${grantId}`,
      steps
    );
    return response.data;
  };

  fetchGinsSteps = async (grant_id: string): Promise<Step[]> => {
    const response = await this.createAxios().get(`/gin/step/${grant_id}`);
    return response.data;
  };

  createGinContact = async (
    contact: AdminGinContactResponse
  ): Promise<AdminGinContactResponse> => {
    const response = await this.createAxios().post(`/gin/contact`, contact);

    return response.data;
  };

  deleteGinContact = async (
    ginContactId: string,
    grantId: string
  ): Promise<string> => {
    const response = await this.createAxios().delete<string>(
      `/gin/${grantId}/contact/delete/${ginContactId}`
    );
    return response.data;
  };

  getGinHistory = async (
    grantId: string,
    section: string
  ): Promise<GinHistory[]> => {
    const response = await this.createAxios().get(
      `/gin/history?grantId=${grantId}&section=${section}`
    );

    return response.data;
  };

  updateGinHistory = async (ginId: string) => {
    const response = await this.createAxios().put(`/gin/history/${ginId}`);

    return response.data;
  };
}

