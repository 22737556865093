import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

export default class AdminApi {
  private readonly baseURL: string;
  private readonly accessToken: string;

  constructor(baseUrl: string, accessToken: string) {
    this.baseURL = baseUrl;
    this.accessToken = accessToken;
  }

  createAxios = (contentType: string = "application/json"): AxiosInstance => {
    return axios.create({
      baseURL: this.baseURL + "/admin",
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  };

  get = async <T>(
    url: string,
    config?: AxiosRequestConfig,
    contentType?: string
  ): Promise<AxiosResponse<T>> => {
    return await this.createAxios(contentType)
      .get<T>(url, config)
      .catch((e) => e);
  };

  post = async <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    contentType?: string
  ): Promise<AxiosResponse<T>> => {
    return await this.createAxios(contentType).post<T>(url, data, config);
  };

  put = async <T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig,
    contentType?: string
  ): Promise<AxiosResponse<T>> => {
    return await this.createAxios(contentType).put<T>(url, data, config);
  };

  delete = async <T>(
    url: string,
    config?: AxiosRequestConfig,
    contentType?: string
  ): Promise<AxiosResponse<T>> => {
    return await this.createAxios(contentType).delete<T>(url, config);
  };

  patch = async <T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig,
    contentType?: string
  ): Promise<AxiosResponse<T>> => {
    return await this.createAxios(contentType).patch<T>(url, data, config);
  };
}

