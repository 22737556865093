import Close from "@hellodarwin/icons/dist/icons/Close";
import classNames from "classnames";
import { CSSProperties, PropsWithChildren, ReactNode } from "react";
import { createPortal } from "react-dom";
import {
  ModalBody,
  ModalCloseButton,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalWrapper,
} from "./styles";
import useModalOpenFunctions from "./use-modal-open-functions";

export interface ModalProps extends PropsWithChildren {
  open: boolean;
  handleCancel: () => void;
  afterClose?: () => void;
  size?: "small" | "medium" | "large" | "extra-large" | "full";
  noPadding?: boolean;
  className?: string;
  styles?: {
    wrapper?: CSSProperties;
    content?: CSSProperties;
    container?: CSSProperties;
    body?: CSSProperties;
  };
  style?: CSSProperties;
  header?: ReactNode;
  footer?: ReactNode;
}

const Modal = ({
  children,
  open,
  handleCancel,
  size = "medium",
  noPadding,
  className,
  styles,
  style,
  header,
  footer,
  afterClose,
}: ModalProps) => {
  const { onCancel, fadeOut, isOpen } = useModalOpenFunctions({
    handleCancel,
    open,
    afterClose,
  });
  const classes = classNames("hd-modal", className, { [`fade-out`]: fadeOut });

  if (!isOpen && !fadeOut) return <></>;

  return (
    <>
      {createPortal(
        <ModalWrapper
          onClick={onCancel}
          className={classes}
          style={{ ...styles?.wrapper }}
        >
          <ModalContainer $size={size} style={{ ...styles?.container }}>
            {!!header && (
              <ModalHeader onClick={(e) => e.stopPropagation()}>
                {header}
              </ModalHeader>
            )}
            <ModalContent
              $noPadding={noPadding}
              onClick={(e) => e.stopPropagation()}
              style={{ ...styles?.content, ...style }}
              $withHeader={!!header}
              $withFooter={!!footer}
            >
              <ModalBody style={{ ...styles?.body }}>
                <ModalCloseButton
                  onClick={handleCancel}
                  transparent
                  size="small"
                  headingIcon={<Close width={16} height={16} />}
                ></ModalCloseButton>
                {children}
              </ModalBody>
            </ModalContent>
            {!!footer && (
              <ModalFooter onClick={(e) => e.stopPropagation()}>
                {footer}
              </ModalFooter>
            )}
          </ModalContainer>
        </ModalWrapper>,
        document.getElementById("root")
      )}
    </>
  );
};

export default Modal;

