import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import Edit from "@hellodarwin/icons/dist/icons/Edit";
import Save from "@hellodarwin/icons/dist/icons/Save";
import Drawer from "antd/es/drawer";
import { useState } from "react";
import { GinBlockProps, HandleSaveFuncState } from ".";
import { useAppDispatch, useAppSelector } from "../../../../app";
import {
  fetchGinHistory,
  selectGinHistory,
} from "../../../../features/api/slices/gin-history-slice";

import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import message from "antd/es/message";
import { updateGinWithHistory } from "../../../../features/api/slices/gin-sections-slice";
import { useAdminApi } from "../../../../features/api/use-admin-api";
import { GinApplicationSinglePageSection } from "../../application-single/use-gin-application-blocks";
import { GinGrantSinglePageSection } from "../../grant-single/use-gin-grant-blocks";
import GinHistoryContent from "../gin-history-content";

export interface GinBlockHeaderProps extends GinBlockProps {
  isEditing: boolean;
  handleEditing: () => void;
  handleSave: HandleSaveFuncState;
  handleHistory: () => void;
  showHistory: boolean;
  setContent: (newContent: string) => void;
  setShowHistory: (open: boolean) => void;
}

const GinBlockHeader = ({
  isEditing,
  isSimpleActions,
  isHiddenActions,
  handleEditing,
  id,
  handleSave,
  handleHistory,
  entityId,
  showHistory,
  setContent,
  setShowHistory,
}: GinBlockHeaderProps) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const ginHistory = useAppSelector(selectGinHistory);
  const api = useAdminApi();
  const [selectedHistoryGinId, setSelectedHistoryGinId] = useState<string>("");

  const handleFetchGinHistory = () => {
    handleHistory();
    fetchHistory();
    setSelectedHistoryGinId("");
    setContent("");
  };

  const fetchHistory = async () => {
    await dispatch(fetchGinHistory({ api, grantId: entityId, section: id }));
  };

  const setNewContent = async () => {
    try {
      await dispatch(
        updateGinWithHistory({ api: api, ginId: selectedHistoryGinId })
      ).then(() => {
        setShowHistory(!showHistory);
        message.success(t("gin_history|sectionUpdated"));
      });
    } catch {
      message.error(t("gin_history|sectionUpdatedError"));
    }
  };

  return (
    <>
      <Div flex="row" align="center" justify="space-between">
        <Typography.Title level={1} elementTheme="h6" style={{ margin: 0 }}>
          {t(`gin_section|${id}`)}
        </Typography.Title>{" "}
        <Div
          flex="row"
          gap={8}
          fitContent
          align="center"
          style={{ minHeight: 32 }}
        >
          {!!handleSave &&
            isEditing &&
            !isSimpleActions &&
            !isHiddenActions && (
              <Button
                onClick={handleSave}
                size="small"
                style={{ minWidth: 140 }}
                defaultStyle={theme.colors.primary}
              >
                {t("button|save")}
              </Button>
            )}

          {!(isHiddenActions || (isSimpleActions && isEditing)) && (
            <Button
              onClick={handleEditing}
              size="small"
              style={{ minWidth: 140 }}
              defaultStyle={
                isEditing ? theme.colors.white_1 : theme.colors.grey_1
              }
              headingIcon={isEditing ? null : <Edit size={16} />}
            >
              {isEditing ? t("button|cancel") : t("button|edit")}
            </Button>
          )}
          {!isEditing &&
            id !== GinApplicationSinglePageSection.Files &&
            id !== GinGrantSinglePageSection.ProductionStages &&
            id !== GinApplicationSinglePageSection.ImportantDate &&
            id !== GinApplicationSinglePageSection.Notes && (
              <Button
                onClick={handleFetchGinHistory}
                size="circle"
                defaultStyle={theme.colors.primary}
                headingIcon={<Timeline size={12} />}
              />
            )}
        </Div>
      </Div>
      <Drawer
        open={showHistory}
        onClose={handleHistory}
        title={t(`gin_history|title`) + " " + t(`gin_section|${id}`)}
        styles={{ mask: { background: "transparent" } }}
        extra={
          <Button
            size="circle"
            defaultStyle={theme.colors.white_1}
            onClick={setNewContent}
            disabled={!ginHistory}
          >
            <Save size={16} />
          </Button>
        }
      >
        <Div gap={10}>
          <GinHistoryContent
            history={ginHistory}
            setContent={setContent}
            setSelectedGin={setSelectedHistoryGinId}
            selectedGin={selectedHistoryGinId}
          />
        </Div>
      </Drawer>
    </>
  );
};
export default GinBlockHeader;

