import message from "antd/es/message";
import { useMemo } from "react";
import {
  AssetRecordInformation,
  UploadAsset,
  UploadAssetResponse,
} from "../entities/assets-entities";

export type HandleSaveAssetFunction = (
  formData: FormData
) => Promise<"rejected" | "fulfilled">;
export interface useOnMultipleFilesUploadProps {
  handleSaveAsset: HandleSaveAssetFunction;
  successMessage: string;
  errorMessage: string;
}

const useOnMultipleFilesUpload = ({
  handleSaveAsset,
  successMessage,
  errorMessage,
}: useOnMultipleFilesUploadProps) => {
  return useMemo(
    () => async (assets: UploadAsset[], records: AssetRecordInformation[]) => {
      try {
        if (!assets.length) return;

        let successed = true;
        for (const assetData of assets) {
          const formData = new FormData();

          formData.append("file", assetData.asset);

          if (assetData.values.record_id) {
            records.push({
              record_id: assetData.values.record_id,
              record_type: assetData.values.record_type,
            });
          }
          const fileRequest: UploadAssetResponse = {
            file_name: assetData.values.file_name,
            file_description: assetData.values.file_description,
            records: records,
            version: assetData.values.version,
            asset_id: assetData.values.asset_id,
          };

          formData.append("request", JSON.stringify(fileRequest));

          const resultRequest = await handleSaveAsset(formData);
          if (resultRequest === "rejected") {
            successed = false;
          }
        }

        if (successed) {
          message.success(successMessage);
        } else {
          message.error(errorMessage);
        }
      } catch (e: any) {
        message.error(errorMessage);
        console.error(e);
      }
    },
    [handleSaveAsset, successMessage, errorMessage]
  );
};
export default useOnMultipleFilesUpload;

