import { useEffect, useMemo, useState } from "react";

const useModalOpenFunctions = ({
  handleCancel,
  afterClose,
  open,
}: {
  handleCancel: () => void;
  afterClose?: () => void;
  open?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [fadeOut, setFadeOut] = useState(false);

  const closeWithFade = () => {
    setIsOpen((prevOpen) => {
      if (prevOpen === false) {
        return false;
      } else {
        setFadeOut(true);
        setTimeout(() => {
          setFadeOut(false);
        }, 300);

        return false;
      }
    });
  };

  useEffect(() => {
    if (open) {
      setIsOpen(true);
      setFadeOut(false);
    } else {
      closeWithFade();
    }
  }, [open]);

  const onCancel = () => {
    if (handleCancel) {
      handleCancel();
    } else {
      closeWithFade();
    }
  };

  useEffect(() => {
    if (!open && !fadeOut && !!afterClose) {
      afterClose();
    }
  }, [open, fadeOut]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return useMemo(() => {
    return { isOpen, fadeOut, onCancel };
  }, [isOpen, fadeOut, onCancel]);
};

export default useModalOpenFunctions;

