import Delete from "@hellodarwin/icons/dist/icons/Delete";
import Edit from "@hellodarwin/icons/dist/icons/Edit";
import Hide from "@hellodarwin/icons/dist/icons/Hide";
import View from "@hellodarwin/icons/dist/icons/View";
import Tooltip from "antd/es/tooltip";
import React, { MouseEventHandler } from "react";
import { AssetRowMeta } from "../../../../features/entities/assets-entities";
import { useTranslation } from "../../../../plugins/i18n";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../../common/button";
import Div from "../../../common/div";
import Typography from "../../../common/typography";
import { AssetEditRowContainer } from "./styles";

interface AssetEditRowProps {
  meta: AssetRowMeta;
  editAsset: (meta: AssetRowMeta | undefined) => void;
}
const AssetEditRow: React.FC<AssetEditRowProps> = ({ editAsset, meta }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const handleClick = () => {
    editAsset(meta);
  };

  const handleHideShow: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (meta.actions.onHideShow) meta.actions.onHideShow(!meta.hidden);
  };

  const handleDeleteRow: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    if (meta.actions.onDelete) meta.actions.onDelete();
  };

  return (
    <>
      <AssetEditRowContainer onClick={handleClick} $hidden={!!meta.hidden}>
        <Typography elementTheme="body3" textAlign="right">
          {meta.name}
        </Typography>

        <Div gap={8} flex="row" align="center" fitContent>
          <Tooltip
            title={
              meta.hidden
                ? t("assets|show_user_asset")
                : t("assets|hide_user_asset")
            }
          >
            <Button
              size="square"
              transparent
              defaultStyle={theme.colors.primary}
              onClick={handleHideShow}
            >
              {meta.hidden ? <Hide size={16} /> : <View size={16} />}
            </Button>
          </Tooltip>
          <Button
            size="square"
            transparent
            defaultStyle={theme.colors.primary}
            onClick={handleClick}
          >
            <Edit size={16} />
          </Button>
          <Button
            size="square"
            transparent
            defaultStyle={theme.colors.primary}
            onClick={handleDeleteRow}
          >
            <Delete size={16} />
          </Button>
        </Div>
      </AssetEditRowContainer>
    </>
  );
};

export default AssetEditRow;

