import { FormInstance } from "antd/es/form/hooks/useForm";
import { useCallback } from "react";
import { Company } from "../../../../features/entities";
import isEmptyStringOrArray from "../../../../features/helpers/is-empty-string-or-array";
import { FundingExplorerFilterValues } from "../filter/types";

interface CheckCompanyFiltersProps {
  form: FormInstance<FundingExplorerFilterValues>;
  company: Company;
  showCompanyFilterCardState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ];
}

const compareValues = (
  value1: string | string[],
  value2: string | string[]
) => {
  if (Array.isArray(value1) !== Array.isArray(value2)) return false;

  if (Array.isArray(value1)) {
    if (value1?.length !== value2?.length) return false;

    return value1?.every((element) => value2?.includes(element));
  } else {
    return value1 ? value2 === value1 : !value2;
  }
};

const CheckCompanyFilters = ({
  form,
  company,
  showCompanyFilterCardState,
}: CheckCompanyFiltersProps) => {
  const filterValues = form.getFieldsValue();

  return useCallback(() => {
    if (!company) return false;

    const {
      annualRevenue,
      industry: industries,
      subindustry: subindustries,
      region,
      companySize,
    } = form.getFieldsValue();

    if (
      !annualRevenue &&
      !industries &&
      !subindustries &&
      !region &&
      !companySize
    ) {
      return false;
    }

    const industryMatch =
      isEmptyStringOrArray(industries) &&
      isEmptyStringOrArray(company.industry_sector)
        ? true
        : compareValues(company.industry_sector, industries);

    const subindustryMatch = compareValues(
      company.industry_subsector,
      subindustries
    );

    const statusMatch = filterValues.status?.length === 0 || true;

    const financingTypeMatch = filterValues.financingType?.length === 0 || true;

    const regionMatch = company.province
      ? region?.length === 1 && region[0] === company.province
      : !region?.length;

    const closingDateMatch = filterValues.closingDate
      ? new Date(filterValues.closingDate).getTime() === null
      : true;

    const annualRevenueMatch = compareValues(
      company.annual_revenue,
      annualRevenue
    );

    const companySizeMatch = compareValues(company.size, companySize);
    const serviceMatch = filterValues.service?.length === 0 || true;

    if (
      industryMatch &&
      subindustryMatch &&
      statusMatch &&
      financingTypeMatch &&
      regionMatch &&
      closingDateMatch &&
      serviceMatch &&
      annualRevenueMatch &&
      companySizeMatch
    ) {
      return true;
    } else {
      return false;
    }
  }, [form, company, showCompanyFilterCardState]);
};

export default CheckCompanyFilters;

