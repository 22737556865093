import { useMemo } from "react";
import FileSectionGin from "../gin-single/file-section";
import { GinBlockConfig } from "../gin-single/gin-block";
import GinTextEditor from "../gin-single/gin-editor";
import ApplicationTimeline from "./application-timeline";

export enum GinApplicationSinglePageSection {
  Notes = "application_notes",
  ImportantDate = "important_date",
  Files = "files",
}

const useGinApplicationBlocks = () => {
  return useMemo(() => {
    const GinSectionsBlocks: { [section: string]: GinBlockConfig } = {
      [GinApplicationSinglePageSection.Notes]: {
        id: GinApplicationSinglePageSection.Notes,
        Content: GinTextEditor,
      },
      [GinApplicationSinglePageSection.Files]: {
        id: GinApplicationSinglePageSection.Files,
        Content: FileSectionGin,
        isSimpleActions: true,
      },

      [GinApplicationSinglePageSection.ImportantDate]: {
        id: GinApplicationSinglePageSection.ImportantDate,
        Content: ApplicationTimeline,
        isHiddenActions: true,
      },
    };

    return GinSectionsBlocks;
  }, []);
};

export default useGinApplicationBlocks;

