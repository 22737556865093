import Empty from "antd/es/empty";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import {
  AssetEntity,
  AssetFolderEntity,
  AssetItemType,
  AssetOwnerInformation,
  AssetRecordInformation,
  MappedAssetsMetas,
} from "../../../features/entities/assets-entities";
import { HandleSaveAssetFunction } from "../../../features/helpers/use-on-file-finish-upload";
import { useTranslations } from "../../../features/providers/translations-provider";
import Div from "../../common/div";
import Typography from "../../common/typography";
import AssetDrawer from "../asset-drawer";
import useGetAssetActions from "../utils/get-asset-actions";
import useGetAssetMeta from "../utils/get-asset-meta";
import isAssetFolder from "../utils/is-asset-folder";
import FilesListCards from "./files-cards";
import FilesListFooter from "./files-list-footer";
import FilesListHeader from "./files-list-header";
import FilesListTable from "./files-table";

export interface FilesListProps {
  items: AssetItemType[];
  handleDownload: (asset: AssetEntity) => void;
  handleSaveAsset: HandleSaveAssetFunction;
  vaultPath: string;
  activeRecord: AssetRecordInformation | undefined;
  rootRecord: AssetRecordInformation;
  additionalOwners?: AssetOwnerInformation[];
  allowUpload?: boolean;
  noHeader?: boolean;
  title?: string;
  defaultIsRow?: boolean;
  isEdit?: boolean;
  isMultiFiles?: boolean;
  multiDrawerOpen?: boolean;
  handleMultiDrawer?: () => void;
  handleHideShowAsset?: (asset_id: string, hide: boolean) => void;
  handleDeleteAsset?: (asset_id: string) => void;
}

const FilesList = (props: FilesListProps) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    items,
    handleDownload,
    vaultPath,
    noHeader,
    handleSaveAsset,
    activeRecord,
    rootRecord,
    additionalOwners,
    defaultIsRow = true,
    isEdit,
    isMultiFiles,
    multiDrawerOpen,
    handleMultiDrawer,
    handleDeleteAsset,
    handleHideShowAsset,
  } = props;

  const [sortedItems, setSortedItems] = useState(items);
  const [activeItemId, setActiveItemId] = useState<string>();

  const [drawerOpen, setDrawerOpen] = useState(multiDrawerOpen);
  const [isRow, setIsRow] = useState(defaultIsRow);

  useEffect(() => {
    setDrawerOpen(multiDrawerOpen);
  }, [multiDrawerOpen]);

  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const onItemSelected = (asset_id?: string) => {
    setActiveItemId(asset_id);
    if (handleMultiDrawer) {
      handleMultiDrawer();
    } else {
      handleDrawer();
    }
  };

  const onItemAction = (asset: AssetItemType) => {
    const isFolder = isAssetFolder(asset);

    if (isFolder) {
      navigate(`${vaultPath}/${(asset as AssetFolderEntity).record_id}`);
    } else {
      handleDownload(asset as AssetEntity);
    }
  };

  const getAssetActions = useGetAssetActions({
    onItemAction,
    onItemSelected,
    setActiveItemId,
    handleDeleteAsset,
    handleHideShowAsset,
  });
  const getAssetMeta = useGetAssetMeta({ getAssetActions });

  const metas: MappedAssetsMetas = useMemo(() => {
    const metas = items.map(getAssetMeta);

    return metas.reduce(
      (o, meta) => ({ ...o, [meta.id]: meta }),
      {} as MappedAssetsMetas
    );
  }, [items]);

  const activeItem = useMemo(() => metas[activeItemId], [activeItemId]);

  useEffect(() => {
    setSortedItems(items);
  }, [metas]);

  const closeDrawer = () => onItemSelected();

  const Content = useMemo(
    () => (isRow ? FilesListTable : FilesListCards),
    [isRow]
  );

  const records = useMemo(() => {
    if (activeRecord?.record_id) {
      return [rootRecord, activeRecord];
    } else {
      return [rootRecord];
    }
  }, [activeRecord, rootRecord]);

  return (
    <Div flex={noHeader ? "column-reverse" : "column"} gap={24}>
      {!noHeader && (
        <FilesListHeader
          {...props}
          items={sortedItems}
          setSortedItems={setSortedItems}
          isRow={isRow}
          setIsRow={setIsRow}
          records={records}
        />
      )}
      {sortedItems.length ? (
        <Content activeItem={activeItem} items={Object.values(metas)} />
      ) : (
        <Empty
          description={
            <Typography elementTheme="subtitle2" color={theme.colors.grey_2}>
              {t(`assets|no_files`)}
            </Typography>
          }
        />
      )}
      <FilesListFooter {...props} />
      <AssetDrawer
        metas={metas}
        activeMetaId={activeItemId}
        handleSaveAsset={handleSaveAsset}
        open={drawerOpen}
        onClose={closeDrawer}
        records={records}
        owners={additionalOwners}
        isMulti={isMultiFiles}
        isEdit={isEdit}
      />
    </Div>
  );
};

export default FilesList;

