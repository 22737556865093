import UploadIcon from "@hellodarwin/icons/dist/icons/Upload";
import Form from "antd/es/form";
import notification from "antd/es/notification";
import { RcFile, UploadProps } from "antd/es/upload";
import { UploadFile } from "antd/lib/upload";
import { useState } from "react";
import { useTranslation } from "../../../../../plugins/i18n";
import { useTheme } from "../../../../../plugins/styled";
import Typography from "../../../../common/typography";
import StepContainer from "../StepContainer";
import { UploadStyled } from "../styles";

type FileUploaderProps = {
  fileList: UploadFile[];
  name: string;
  handleChange: (fileList: RcFile[]) => void;
};

const FileUploader = ({ fileList, name, handleChange }: FileUploaderProps) => {
  const [isValidFile, setIsValidFile] = useState(true);

  const { t } = useTranslation();
  const theme = useTheme();

  const rejectedFileTypes = [
    "exe",
    "bat",
    "cmd",
    "sh",
    "msi",
    "com",
    "vbs",
    "js",
    "jar",
    "app",
    "scr",
    "gadget",
    "pif",
  ];

  const validateFile = (file: RcFile) => {
    const isFileSizeOk = file.size / 1024 / 1024 < 100;

    const isFileTypeOk = !rejectedFileTypes.includes(
      file.type.split("/")[1].toLowerCase()
    );

    if (!isFileSizeOk || !isFileTypeOk) {
      return false;
    }
    return true;
  };

  const MainStep = () => {
    const beforeUpload: UploadProps["beforeUpload"] = (file) => {
      const isValid = validateFile(file);
      if (isValid) {
        if (isValidFile) {
          handleChange([file]);
        } else {
          notification.error({ type: "error", message: "Upload failed" });
        }
      }
      setIsValidFile(isValid);
      return file;
    };

    const uploadProps: UploadProps = {
      className: "assets-uploader",
      name,
      fileList,
      beforeUpload: beforeUpload,
      maxCount: 1,
      multiple: false,
      onChange: (info) => {
        console.info(info);
      },
      customRequest(options) {
        setTimeout(() => {
          if (options.onSuccess) {
            options.onSuccess("ok", new XMLHttpRequest()), 0;
          }
        }, 500);
      },
    };
    return (
      <StepContainer label={""} description={""}>
        <UploadStyled {...uploadProps}>
          <UploadIcon width={100} height={100} />
          <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
            <Typography
              elementTheme="body3"
              textAlign="center"
              color={theme.colors.grey_2}
            >
              {" "}
              {t("upload.fileSize")}
            </Typography>
          </div>
        </UploadStyled>
      </StepContainer>
    );
  };

  return (
    <div
      style={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: 16,
      }}
    >
      <MainStep />
    </div>
  );
};

export default FileUploader;

FileUploader.Demo = () => {
  const [fileList, setFileList] = useState<RcFile[]>([]);

  const handleUploadChange = (newFileList: RcFile[]) => {
    setFileList(newFileList);
  };

  return (
    <Form layout="vertical">
      <FileUploader
        fileList={fileList}
        name={"file"}
        handleChange={handleUploadChange}
      />
    </Form>
  );
};

