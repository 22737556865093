import { CollapsePanelProps } from "@hellodarwin/core/lib/components/common/Collapse/CollapsePanel";
import TabArrowIcon from "@hellodarwin/icons/dist/icons/TabArrow";
import { ReactNode } from "react";
import GinGrantSummary, { GinSummaryProps } from "../gin-grant-summary";
import { GinSingleHeaderContainer } from "../styles";

interface GinGrantHeaderProps extends GinSummaryProps {
  HeaderContent: () => ReactNode;
}

const GinSingleHeader = ({
  HeaderContent,
  ...ginSummaryProps
}: GinGrantHeaderProps) => {
  const collapseItems: CollapsePanelProps[] = [
    {
      id: "gin",
      title: <HeaderContent />,
      children: <GinGrantSummary {...ginSummaryProps} />,
    },
  ];

  return (
    <GinSingleHeaderContainer
      items={collapseItems}
      expandIconPosition="center"
      ExpandIcon={({ isActive }) => <TabArrowIcon size={24} down={!isActive} />}
      styles={{
        panelContent: { padding: 0 },
      }}
    />
  );
};

export default GinSingleHeader;

