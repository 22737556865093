import Divider from "antd/es/divider";
import Form from "antd/es/form";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { FormData, FormViewerType, ViewerForms } from "..";
import {
  FormAssetList,
  TranslatedFormPageResponse,
  TranslatedFormResponse,
} from "../../../../features/entities/form-entities";
import { useTheme } from "../../../../plugins/styled";
import Div from "../../../common/div";
import Typography from "../../../common/typography";
import FormInput from "../form-input";
import FormCurrentPageHeader from "./form-current-page-header";

interface FormCurrentPageProps {
  formResponse: TranslatedFormResponse;
  currentPage: TranslatedFormPageResponse;
  handleClose: () => void;
  formData: FormData;
  setForms: Dispatch<SetStateAction<ViewerForms>>;
  handleNext: () => void;
  isEndPage: boolean;
  isOpening: boolean;
  viewerType: FormViewerType;
  formAssetList?: FormAssetList[];
  setFormAssetList?: Dispatch<SetStateAction<FormAssetList[]>>;
}

const FormCurrentPage = ({
  formResponse,
  currentPage,
  handleClose,
  formData,
  handleNext,
  setForms,
  isEndPage,
  isOpening,
  viewerType,
  formAssetList,
  setFormAssetList,
}: FormCurrentPageProps) => {
  const theme = useTheme();
  const [form] = Form.useForm();

  useEffect(() => {
    setForms((prevState) => ({
      ...prevState,
      dataForm: form,
    }));
  }, [form, setForms]);

  if (formResponse?.pages?.length === 0) return null;

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={formData}
      onFinish={handleNext}
      name={currentPage.page_title}
    >
      <Div flex="column" gap={32}>
        <FormCurrentPageHeader
          page={currentPage}
          handleClose={handleClose}
          form_type={formResponse.form_type}
          isEndPage={isEndPage}
          viewerType={viewerType}
          isOpening={isOpening}
        />
        {!!currentPage?.groups?.length && (
          <Div flex="column" align="center" style={{ width: "100%" }}>
            {React.Children.toArray(
              currentPage.groups?.map((group, index) => (
                <>
                  <Div flex="column" gap={16}>
                    {!!group.group_title && (
                      <Typography elementTheme="subtitle2">
                        {group.group_title}
                      </Typography>
                    )}
                    <Div
                      flex={
                        group.group_disposition === "vertical"
                          ? "column"
                          : "row"
                      }
                      gap={group.group_disposition === "vertical" ? 16 : 32}
                    >
                      {React.Children.toArray(
                        group.items.map((item) => (
                          <FormInput
                            formItem={item}
                            formAssetList={formAssetList}
                            setFormAssetList={setFormAssetList}
                          />
                        ))
                      )}
                    </Div>
                  </Div>
                  {index < currentPage?.groups?.length - 1 && (
                    <Divider
                      style={{
                        borderColor: theme.colors.grey_3,
                        margin: "16px 0",
                      }}
                    />
                  )}
                </>
              ))
            )}
          </Div>
        )}
      </Div>
    </Form>
  );
};

export default FormCurrentPage;

