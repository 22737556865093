import { IconProps } from "@hellodarwin/icons/dist/features/entities/general";
import { ReactNode } from "react";
import Div from "../div";
import Typography from "../typography";

export interface InfoFieldProps {
  Icon: (props: IconProps) => ReactNode;
  label: string;
  value: string;
}

const InfoField = ({ Icon, label, value }: InfoFieldProps) => {
  return (
    <Div flex="row" gap={8} align="center" fitContent>
      <Icon size={16} />
      <Typography elementTheme="body3">
        {!!label && <b>{label}</b>}
        {value}
      </Typography>
    </Div>
  );
};

export default InfoField;

