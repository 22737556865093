import ErrorNotFound from "@hellodarwin/core/lib/components/error/notfound";
import React, { ReactNode, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAppSelector } from "./app/app-hooks";
import { selectAuthentifiedUser } from "./features/api/slices/global-slice";
import adminAppPages, { MappedAdminAppPages } from "./pages";

const renderRoutes = (pages: MappedAdminAppPages): ReactNode => {
  return React.Children.toArray(
    Object.entries(pages).map(([path, page]) => (
      <>
        <Route key={path} path={page.pathname} element={page.component} />
        {!!page.children ? renderRoutes(page.children) : undefined}
      </>
    ))
  );
};

const AppRoutes = () => {
  const admin = useAppSelector(selectAuthentifiedUser);

  const defaultHomepage = useMemo(
    () => admin.admin_homepage ?? "/projects",
    [admin]
  );

  return (
    <Routes>
      <Route path="/" element={<Navigate to={defaultHomepage} />} />

      {renderRoutes(adminAppPages)}

      <Route path="*" element={<ErrorNotFound />} />
    </Routes>
  );
};

export default AppRoutes;

