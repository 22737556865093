import AddNew from "@hellodarwin/icons/dist/icons/AddNew";
import Drawer from "antd/es/drawer";
import React, { useEffect, useState } from "react";
import {
  AssetOwnerInformation,
  AssetRecordInformation,
  AssetRowMeta,
  MappedAssetsMetas,
} from "../../../../features/entities/assets-entities";
import { HandleSaveAssetFunction } from "../../../../features/helpers/use-on-file-finish-upload";
import Button from "../../../common/button";
import Div from "../../../common/div";
import AssetEditRow from "../asset-editor-drawer/edit-row";
import AssetEditorDrawer from "../asset-editor-drawer/edit-single-asset-drawer";
import AssetViewerDrawer from "../asset-viewer-drawer";

interface MultiAssetsDrawerProps {
  open: boolean;
  onClose: () => void;
  metas: MappedAssetsMetas;
  handleSaveAsset: HandleSaveAssetFunction;
  records: AssetRecordInformation[];
  owners: AssetOwnerInformation[];
  isEdit?: boolean;
  activeMetaId?: string;
}

const MultiAssetsDrawer: React.FC<MultiAssetsDrawerProps> = ({
  open,
  onClose,
  metas,
  isEdit,
  handleSaveAsset,
  records,
  owners,
  activeMetaId,
}) => {
  const [singleFileOpen, setSingleFileOpen] = useState(false);
  const [selectedMeta, setSelectedMeta] = useState<AssetRowMeta>();

  const handleNewFile = () => openSingleDrawer(undefined);

  const openSingleDrawer = (meta?: AssetRowMeta) => {
    setSelectedMeta(meta);
    setSingleFileOpen(true);
  };
  const closeSingleDrawer = () => {
    setSelectedMeta(undefined);
    setSingleFileOpen(false);
  };

  useEffect(() => {
    if (activeMetaId?.length) {
      openSingleDrawer(metas[activeMetaId]);
    }
  }, [activeMetaId]);

  return (
    <>
      <Drawer
        title="Files"
        size="default"
        placement="right"
        closable={true}
        open={open}
        onClose={onClose}
        extra={
          <Button size="circle" fitContent onClick={handleNewFile} transparent>
            <AddNew size={24} />
          </Button>
        }
      >
        <Div flex="column" gap={8}>
          {React.Children.toArray(
            Object.values(metas).map((meta) => (
              <AssetEditRow editAsset={openSingleDrawer} meta={meta} />
            ))
          )}
        </Div>
        {isEdit ? (
          <AssetEditorDrawer
            onClose={closeSingleDrawer}
            open={singleFileOpen}
            meta={selectedMeta}
            handleSaveAsset={handleSaveAsset}
            records={records}
            owners={owners}
          />
        ) : (
          <AssetViewerDrawer
            onClose={closeSingleDrawer}
            open={singleFileOpen}
            meta={selectedMeta}
          />
        )}
      </Drawer>
    </>
  );
};

export default MultiAssetsDrawer;

