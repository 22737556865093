import { ProgramSingleTabs } from "@hellodarwin/core/lib/components/programs/single";
import { ReactNode } from "react";
import AdminFileVault from "./admin-files-page";
import AdminsPage from "./admins-page";
import CompaniesPage from "./companies-page";
import FormSubmissionSinglePage from "./form-submission-single-page";
import FormsPage from "./forms-page";
import FundingExplorer from "./funding-explorer";
import FundingExplorerSingleGrantPage from "./funding-explorer/single-grant";
import GinList from "./gin";
import SingleGinPage from "./gin/single-grant";
import GrantsPage from "./grants-page";
import HdChatsPage from "./hd-chats-page";
import IconsViewerPage from "./icons-viewer-page";
import ProgramsPage from "./programs-page";
import ProjectsPage from "./projects-page";
import ProvidersPage from "./providers-page";
import ReviewsPage from "./reviews-page";
import SearchGrantsPage from "./search-grants-page";
import SettingsPage from "./settings-page";
import EditAdminSettingsPage from "./settings-page/edit-admin-settings-page";
import SingleCompanyPage, { CompanyContent } from "./single-company-page";
import SingleCompanyProgramPage from "./single-company-program-page";
import SingleFormPage from "./single-form-page";
import SingleFormPageSubmissions from "./single-form-page-submissions";
import CreateFormPage from "./single-form-page/create-form-page";
import SingleFormPreviewPage from "./single-form-preview-page";
import SingleGrantContextPage from "./single-grant-context-page";
import SingleGrantPage from "./single-grant-page";
import CreateGrantPage from "./single-grant-page/create-grant";
import SingleGrantProviderPage from "./single-grant-provider-page";
import CreateGrantProviderPage from "./single-grant-provider-page/create-grant-provider";
import SingleChatPage from "./single-hd-chat-page";
import SingleProgramPage from "./single-program-page";
import SingleProjectPage from "./single-project-page";
import SingleProviderPage from "./single-provider-page";

export interface AdminAppPage {
  pathname: string;
  title: string;
  component: ReactNode;
  children?: MappedAdminAppPages;
  not_homepage?: boolean;
}

export interface MappedAdminAppPages {
  [path: string]: AdminAppPage;
}

const adminAppPages: MappedAdminAppPages = {
  "/projects": {
    pathname: "/projects",
    title: "Projects",
    component: <ProjectsPage />,
    children: {
      ":id": {
        pathname: "/projects/:id",
        title: "Single Project",
        component: <SingleProjectPage />,
      },
    },
  },
  "/providers": {
    pathname: "/providers",
    title: "Providers",
    component: <ProvidersPage />,
    children: {
      ":providerId": {
        pathname: "/providers/:providerId",
        title: "Single Provider",
        component: <SingleProviderPage />,
        children: {
          ":defaultSection": {
            pathname: "/providers/:providerId/:defaultSection",
            title: "Provider Section",
            component: <SingleProviderPage />,
          },
        },
      },
    },
  },
  "/companies": {
    pathname: "/companies",
    title: "Companies",
    component: <CompaniesPage />,
    children: {
      ":id": {
        pathname: "/companies/:id",
        title: "Single Company",
        component: <SingleCompanyPage defaultTab={CompanyContent.Company} />,
        children: {
          users: {
            pathname: "/companies/:id/users",
            title: "Company Users",
            component: <SingleCompanyPage defaultTab={CompanyContent.Users} />,
          },
          projects: {
            pathname: "/companies/:id/projects",
            title: "Company Projects",
            component: (
              <SingleCompanyPage defaultTab={CompanyContent.Projects} />
            ),
          },
          grants: {
            pathname: "/companies/:id/grants",
            title: "Company Grants",
            component: <SingleCompanyPage defaultTab={CompanyContent.Grants} />,
          },
          submissions: {
            pathname: "/companies/:id/submissions",
            title: "Company Submissions",
            component: (
              <SingleCompanyPage defaultTab={CompanyContent.FormsSubmissons} />
            ),
          },
          files: {
            pathname: "/companies/:id/files",
            title: "Company Files",
            component: (
              <SingleCompanyProgramPage
                defaultSection={ProgramSingleTabs.ProgramFiles}
              />
            ),
            children: {
              ":folderId": {
                pathname: "/companies/:id/files/:folderId",
                title: "Company Files - Folder",
                component: (
                  <SingleCompanyProgramPage
                    defaultSection={ProgramSingleTabs.ProgramFiles}
                  />
                ),
              },
            },
          },
        },
      },
      ":companyId/grants/:grantId": {
        pathname: "/companies/:companyId/grants/:grantId",
        title: "Company Program",
        component: <SingleCompanyProgramPage />,
      },
    },
  },
  "/reviews": {
    pathname: "/reviews",
    title: "Reviews",
    component: <ReviewsPage />,
  },
  "/programs": {
    pathname: "/programs",
    title: "Programs",
    component: <ProgramsPage />,
    children: {
      ":programId": {
        pathname: "/programs/:programId",
        title: "Single Program",
        component: <SingleProgramPage />,
      },
    },
  },
  "/settings": {
    pathname: "/settings",
    title: "Settings",
    component: <SettingsPage />,
    not_homepage: true,
    children: {
      ":adminId": {
        pathname: "/settings/:adminId",
        title: "Edit Admin Settings",
        component: <EditAdminSettingsPage />,
      },
    },
  },
  "/admins": {
    pathname: "/admins",
    title: "Admins",
    component: <AdminsPage />,
  },
  "/grants": {
    pathname: "/grants",
    title: "Grants",
    component: <GrantsPage defaultKey="grants" />,
    children: {
      providers: {
        pathname: "/grants/providers",
        title: "Grant Providers",
        component: <GrantsPage defaultKey="grants-providers" />,
        children: {
          create: {
            pathname: "/grants/providers/create",
            title: "Create Grant Provider",
            component: <CreateGrantProviderPage />,
          },
          ":id": {
            pathname: "/grants/providers/:id",
            title: "Single Grant Provider",
            component: <SingleGrantProviderPage defaultTab="grant-provider" />,
            children: {
              contacts: {
                pathname: "/grants/providers/:id/contacts",
                title: "Provider Contacts",
                component: <SingleGrantProviderPage defaultTab="contacts" />,
              },
            },
          },
        },
      },
      applications: {
        pathname: "/grants/applications",
        title: "Grant Applications",
        component: <GrantsPage defaultKey="grants-applications" />,
      },
      create: {
        pathname: "/grants/create",
        title: "Create Grant",
        component: <CreateGrantPage />,
      },
      ":id": {
        pathname: "/grants/:id",
        title: "Single Grant",
        component: <SingleGrantPage />,
      },
      "scrape-context/:id": {
        pathname: "/grants/scrape-context/:id",
        title: "Grant Context",
        component: <SingleGrantContextPage />,
      },
    },
  },
  "/funding-explorer": {
    pathname: "/funding-explorer",
    title: "Funding Explorer",
    component: <FundingExplorer />,
    children: {
      ":id": {
        pathname: "/funding-explorer/:id",
        title: "Funding Explorer Grant",
        component: <FundingExplorerSingleGrantPage />,
      },
    },
  },
  "/gin": {
    pathname: "/gin",
    title: "Gin List",
    component: <GinList />,
    children: {
      ":id": {
        pathname: "/gin/:id",
        title: "Single Gin",
        component: <SingleGinPage />,
      },
    },
  },
  "/search-grants": {
    pathname: "/search-grants",
    title: "Search Grants",
    component: <SearchGrantsPage />,
    children: {
      ":id": {
        pathname: "/search-grants/:id",
        title: "Funding Explorer Grant",
        component: <FundingExplorerSingleGrantPage />,
      },
    },
  },
  "/forms": {
    pathname: "/forms",
    title: "Forms",
    component: <FormsPage defaultKey="forms" />,
    children: {
      submissions: {
        pathname: "/forms/submissions",
        title: "Form Submissions",
        component: <FormsPage defaultKey="forms_submissions" />,
        children: {
          ":formSubmissionId": {
            pathname: "/forms/submissions/:formSubmissionId",
            title: "Single Form Submission",
            component: <FormSubmissionSinglePage />,
          },
        },
      },
      ":id": {
        pathname: "/forms/:id",
        title: "Single Form",
        component: <SingleFormPage />,
        children: {
          submissions: {
            pathname: "/forms/:id/submissions",
            title: "Form Submissions",
            component: <SingleFormPageSubmissions />,
          },
          preview: {
            pathname: "/forms/:id/preview",
            title: "Form Preview",
            component: <SingleFormPreviewPage />,
          },
        },
      },
      create: {
        pathname: "/forms/create",
        title: "Create Form",
        component: <CreateFormPage />,
      },
    },
  },
  "/chats": {
    pathname: "/chats",
    title: "Chats",
    component: <HdChatsPage defaultKey="chats" />,
    children: {
      ":id": {
        pathname: "/chats/:id",
        title: "Single Chat",
        component: <SingleChatPage />,
      },
      prompts: {
        pathname: "/chats/prompts",
        title: "Chat Prompts",
        component: <HdChatsPage defaultKey="prompts" />,
      },
      templates: {
        pathname: "/chats/templates",
        title: "Chat Templates",
        component: <HdChatsPage defaultKey="templates" />,
      },
    },
  },
  "/files-vault": {
    pathname: "/files-vault",
    title: "Files Vault",
    component: <AdminFileVault />,
    children: {
      ":folderId": {
        pathname: "/files-vault/:folderId",
        title: "Files Vault",
        component: <AdminFileVault />,
      },
    },
  },
  "/icons": {
    pathname: "/icons",
    title: "Icons Viewer",
    component: <IconsViewerPage />,
    not_homepage: true,
  },
};

export default adminAppPages;

