import Quill from "quill";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
const Delta = Quill.import("delta");

interface RichTextEditorProps {
  initialValue: string;
  id?: string;
  onChange?: (...props: any) => void;
}

const customMatcher = (node, delta) => {
  return delta.compose(new Delta().retain(delta.length(), { header: false }));
};

const quillModules = {
  toolbar: [
    [{ header: [3, 4, false] }],
    ["bold", "underline", "italic", { list: "bullet" }, { list: "ordered" }],
    ["link"],
  ],
  clipboard: {
    matchVisual: false,
    matchers: [[Node.ELEMENT_NODE, customMatcher]],
  },
};

const RichTextEditor = (props: RichTextEditorProps) => {
  const ref = useRef<ReactQuill>();

  const { initialValue } = props;
  const [content, setContent] = useState(initialValue);

  useEffect(() => {
    setContent(initialValue);
  }, [initialValue]);

  const handleChange = (newValue: string) => {
    props.onChange(newValue);
    setContent(newValue);
  };

  return (
    <ReactQuill
      className="text-editor"
      theme="snow"
      ref={ref}
      modules={quillModules}
      preserveWhitespace={true}
      value={content}
      onChange={handleChange}
    />
  );
};

export default RichTextEditor;

