import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import FormViewer from "@hellodarwin/core/lib/components/forms/form-viewer";
import { AdminFormSubmissionResponse } from "@hellodarwin/core/lib/features/entities/form-entities";
import useOnMultipleFilesUpload from "@hellodarwin/core/lib/features/helpers/use-on-multiple-files-upload";
import { ThemeProvider } from "@hellodarwin/core/lib/plugins/styled";
import theme from "@hellodarwin/core/lib/theme";
import getAntdTheme from "@hellodarwin/core/lib/theme/antd-theme";
import { GlobalTypographyStyling } from "@hellodarwin/core/lib/theme/global-style";
import ConfigProvider from "antd/es/config-provider";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app";
import { uploadAsset } from "../../features/api/slices/assets-slice";
import {
  fetchTranslatedFormByID,
  selectActiveTranslatedForm,
  selectFormIsLoading,
} from "../../features/api/slices/forms-slice";
import { useAdminApi, useNewAdminApi } from "../../features/api/use-admin-api";
import { FormPreviewContainer } from "./styles";

const SingleFormPreviewPage = () => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const newApi = useNewAdminApi();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const loading = useAppSelector((state) => selectFormIsLoading(state));
  const formResponse = useAppSelector(selectActiveTranslatedForm);

  useEffect(() => {
    if (id) {
      dispatch(fetchTranslatedFormByID({ api, formId: id }));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    navigate(`/forms/${id}`);
  };

  const handleSaveAsset = async (
    formData: FormData
  ): Promise<"rejected" | "fulfilled"> =>
    (
      await dispatch(
        uploadAsset({
          api: newApi,
          formData,
        })
      )
    ).meta.requestStatus;

  const uploadAllFiles = useOnMultipleFilesUpload({
    handleSaveAsset,
    successMessage: "Successfuly uploaded file",
    errorMessage: "Something went wrong, try again later!",
  });

  const handleSave = async (props: AdminFormSubmissionResponse) => {
    const submissions = await api.createFormSubmission(props);

    const uploadAssets = props.uploadAssets.map((asset) => ({
      ...asset,
      values: {
        ...asset.values,
        record_id:
          submissions.submissionItems.find(
            (itm) => itm.form_item_id === asset.values.record_id
          )?.form_submission_item_id ?? asset.values.record_id,
      },
    }));

    uploadAllFiles(uploadAssets, [
      {
        record_id: submissions.submission.form_id,
        record_type: "form",
      },
      {
        record_id: submissions.submission.form_submission_id,
        record_type: "form_submission",
      },
    ]);

    return submissions.submission.form_submission_id;
  };

  const antdTheme = getAntdTheme(theme);

  return (
    <PageLayout
      app="admin"
      tabTitle={"Preview Form"}
      handleBack={handleBack}
      title={"Preview Form"}
      breadcrumbs={[
        {
          breadcrumbName: "Forms",
          path: "/forms",
        },
        {
          breadcrumbName: "Edit Form",
          path: `/forms/${formResponse.form_id}`,
        },
        {
          breadcrumbName: "Preview Form",
        },
      ]}
      isLoading={loading}
    >
      <ThemeProvider theme={theme}>
        <ConfigProvider theme={antdTheme}>
          <GlobalTypographyStyling />
          <Div flex="column" align="center">
            <FormPreviewContainer>
              <svg
                width={800}
                height={800}
                viewBox="0 0 316 441"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M174.643 93.6347C184.508 89.9856 195.54 91.2391 204.237 97.1516C228.581 113.694 209.54 155.774 210.877 171.974C211.735 182.346 224.704 181.64 250.414 158.394C264.515 145.649 284.671 182.246 246.741 186.549C214.056 190.254 218.508 209.582 233.651 214.915C236.386 215.877 239.864 214.924 246.614 211.703C285.579 193.116 305.79 204.3 311.9 216.915C333.345 261.193 246.613 276.214 195.021 247.929C170.453 234.46 154.069 207.034 147.444 194.727C138.375 177.878 110.291 117.443 174.637 93.6364L174.643 93.6347Z"
                  fill="currentColor"
                />
                <path
                  d="M78.8055 247.236C58.9059 260.267 41.7372 278.831 39.5294 302.43C38.6444 311.887 41.7807 322.1 34.547 329.723C17.4455 347.732 -2.54677 321.165 0.266683 375.838C3.89923 446.48 51.3596 462.183 102.709 410.631C125.239 388.013 160.652 312.65 154.306 281.218C151.451 267.086 148.119 252.105 134.098 244.731C117.323 235.904 95.7951 236.118 78.8089 247.241L78.8055 247.236Z"
                  fill="currentColor"
                />
                <path
                  d="M42.2386 218.107C8.69675 221.049 11.3544 170.117 27.2813 150.651C41.4025 133.386 76.1184 96.1233 55.4272 77.7114C46.308 69.5957 29.3685 74.1619 19.0875 66.3098C6.74107 58.0451 -2.208 30.797 17.022 25.527C30.5978 21.8064 34.0664 37.4997 42.87 43.737C53.2362 51.0803 65.5351 45.0993 70.2537 34.7045C74.1317 26.1608 74.2132 15.4363 81.0591 8.36996C90.5698 -1.44754 108.78 -3.1196 119.202 7.09467C129.624 17.3089 142.766 37.6653 130.466 60.6985C113.552 92.3694 86.5705 90.3864 82.0898 113.278C78.5408 137.139 85.8697 147.321 83.3008 171.18C81.024 192.303 64.5505 216.151 42.2386 218.107Z"
                  fill="currentColor"
                />
              </svg>
              <div style={{ position: "relative" }}>
                <FormViewer
                  formResponse={formResponse}
                  handleSave={handleSave}
                  handleClose={handleBack}
                  source="admin"
                  page_name="Preview Form"
                  type="preview"
                />
              </div>
            </FormPreviewContainer>
          </Div>
        </ConfigProvider>
      </ThemeProvider>
    </PageLayout>
  );
};

export default SingleFormPreviewPage;

