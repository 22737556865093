import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import HdTag from "@hellodarwin/core/lib/components/common/hd-tag";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import StatusTags from "@hellodarwin/core/lib/components/grants/single/grant-sections/summary/status-tags-list";
import {
  GinApplication,
  ProgramGrantStatus,
} from "@hellodarwin/core/lib/features/entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import HubspotLink from "@hellodarwin/icons/dist/icons/HubspotLink";
import { useForm } from "antd/es/form/Form";
import Select from "antd/es/select";
import { MouseEventHandler } from "react";
import { useAppDispatch, useAppSelector } from "../../../app";
import {
  selectGinApplication,
  toggleApplicationModalForm,
  toggleApplicationModalMessage,
  updateGinApplication,
} from "../../../features/api/slices/gins-slice";
import { selectGrantById } from "../../../features/api/slices/grants-slice";
import { useNewAdminApi } from "../../../features/api/use-admin-api";
import ApplicationMessage from "./application-action-modal/application-message";
import CreateApplicationForm from "./application-action-modal/create-application-form";
import useApplicationAmout from "./hooks/use-application-amout";

const GinApplicationHeaderContent = () => {
  const dispatch = useAppDispatch();
  const [form] = useForm<GinApplication>();
  const api = useNewAdminApi();
  const theme = useTheme();
  const { selectedLocale } = useLocale();
  const { t } = useTranslations();
  const application = useAppSelector(selectGinApplication);
  const application_amout = useApplicationAmout(application);
  const grant = useAppSelector((state) =>
    selectGrantById(state, application.application_program_grant_id ?? "")
  );
  if (!application) return <></>;
  const { application_company_name, application_hubspot_url } = application;

  const { grant_display_title_en, grant_display_title_fr } = grant;

  const applicationOptionsStatus = Object.entries(ProgramGrantStatus).map(
    ([key, value]) => ({
      key: key,
      value: value,
      label: t(`programs_labels|${value}`),
    })
  );

  const handleSave = async (applicationStatus: string) => {
    try {
      const values = await form.validateFields();
      dispatch(
        toggleApplicationModalForm({
          isVisible: false,
          applicationStatus: "",
        })
      );
      const updatedApplication = {
        ...application,
        ...values,
        application_status: applicationStatus,
      };
      const result = await dispatch(
        updateGinApplication({ api, updatedApplication })
      );
      if (updateGinApplication.fulfilled.match(result)) {
        dispatch(
          toggleApplicationModalMessage({
            isVisible: true,
            isSuccessful: true,
            applicationStatus: result.payload.application_status,
          })
        );
      } else {
        dispatch(
          toggleApplicationModalMessage({
            isVisible: true,
            isSuccessful: false,
            applicationStatus: "",
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleOnSelect = async (value: string) => {
    switch (value) {
      case ProgramGrantStatus.OngoingInformationRequired:
      case ProgramGrantStatus.ValidationRequired:
      case ProgramGrantStatus.NotSubmited:
        handleSave(value);
        break;
      default:
        dispatch(
          toggleApplicationModalForm({
            isVisible: true,
            applicationStatus: value,
          })
        );
    }
  };

  const goToHubspot: MouseEventHandler = (e) => {
    e.preventDefault();
    window.open(application_hubspot_url, "_blank");
  };

  return (
    <Div flex="column" gap={12} style={{ position: "relative" }}>
      <Div flex="row" gap={32} justify="space-between" align="center">
        <div onClick={(e) => e.stopPropagation()}>
          <Select
            onSelect={(value) => {
              handleOnSelect(value);
            }}
            placeholder="Select program grant status"
            value={application.application_status}
            style={{ width: "300px" }}
            options={applicationOptionsStatus}
          />
          <CreateApplicationForm form={form} handleSave={handleSave} />
          <ApplicationMessage />
        </div>
        <Div flex="row" gap={16} align="center" fitContent>
          {!!application.application_status && (
            <StatusTags
              application_status={application.application_program_status}
            />
          )}
          {!!application_amout.length && (
            <HdTag
              color={theme.colors.green_1}
              highContrastText
              text={application_amout}
            />
          )}
          {!!application_hubspot_url && (
            <Button
              size="square"
              transparent
              onClick={goToHubspot}
              defaultStyle={theme.colors.purple_1}
              headingIcon={<HubspotLink size={28} />}
            />
          )}
        </Div>
      </Div>
      <Div flex="column" gap={2}>
        <Typography
          elementTheme="subtitle1"
          tablet={{ textAlign: "center" }}
          color={theme.colors.grey_2}
          nowrap
        >
          {t("application_single|companyApplication", {
            company: application_company_name,
          })}
        </Typography>

        <Typography.Title
          level={1}
          elementTheme="h5"
          tablet={{ textAlign: "center" }}
        >
          {selectedLocale === "fr"
            ? grant_display_title_fr
            : grant_display_title_en}
        </Typography.Title>
      </Div>
    </Div>
  );
};

export default GinApplicationHeaderContent;

