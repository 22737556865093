import Budget from "@hellodarwin/icons/dist/icons/Budget";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import { useTheme } from "styled-components";
import { ProgramSinglePageProps } from ".";
import dollarFormat from "../../../features/helpers/dollar-format";
import { getStringDateFromString } from "../../../features/helpers/get-formatted-date";
import { getProgramGrantStatusColor } from "../../../features/helpers/get-status-color";
import useLocale from "../../../features/providers/locale-provider";
import { useTranslations } from "../../../features/providers/translations-provider";
import Div from "../../common/div";
import HdTag from "../../common/hd-tag";
import InfoField from "../../common/infoField";
import Typography from "../../common/typography";

const ProgramSingleHeader = ({
  grant,
  admin,
  profile,
  isLoading,
  ...program
}: ProgramSinglePageProps) => {
  const theme = useTheme();
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();

  return (
    <Div
      flex="column"
      gap={12}
      backgroundColor={theme.colors.white_1}
      borderColor={theme.colors.grey_4}
      borderRadius={4}
      style={{ padding: "24px 32px" }}
    >
      <HdTag
        text={t(`programs_labels|${program.program_grant_status}`)}
        color={getProgramGrantStatusColor(program.program_grant_status)}
      />
      <Div flex="column" gap={6}>
        <Typography
          elementTheme="subtitle1"
          tablet={{ textAlign: "center" }}
          color={theme.colors.grey_2}
        >
          {t("application_single|companyApplication", {
            company: program.company.name,
          })}
        </Typography>
        <Typography.Title
          level={1}
          elementTheme="h4"
          noMargin
          tablet={{ textAlign: "center" }}
        >
          {program.program_name}
        </Typography.Title>
      </Div>

      <Div
        flex="row"
        gap={24}
        align="center"
        style={{ color: theme.colors.grey_2 }}
      >
        <InfoField
          Icon={Timeline}
          label=""
          value={getStringDateFromString(
            program.program_updated_at,
            selectedLocale
          )}
        />
        <div
          style={{
            borderLeft: `1px solid ${theme.colors.grey_3}`,
            alignSelf: "stretch",
          }}
        />
        <InfoField
          Icon={Budget}
          label=""
          value={
            !!program.program_accepted_amount
              ? `${dollarFormat(
                  program.program_accepted_amount,
                  selectedLocale,
                  true
                )}`
              : !!program.program_application_amount
                ? `${dollarFormat(
                    program.program_application_amount,
                    selectedLocale,
                    true
                  )}`
                : !!program.program_targeted_amount
                  ? `${dollarFormat(
                      program.program_targeted_amount,
                      selectedLocale,
                      true
                    )}`
                  : !!grant?.funding_max_amount
                    ? `${dollarFormat(
                        grant.funding_max_amount,
                        selectedLocale,
                        true
                      )}`
                    : dollarFormat(0, selectedLocale, true)
          }
        />
      </Div>
    </Div>
  );
};

export default ProgramSingleHeader;

