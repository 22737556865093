import { IconNode } from "@hellodarwin/icons/dist/features/entities/general";
import { MenuProps } from "antd/es/menu";
import { RcFile } from "antd/es/upload";

export type RecordType =
  | "contact"
  | "project"
  | "provider"
  | "application"
  | "admin"
  | "company"
  | "grant"
  | "form"
  | "form_item"
  | "form_submission"
  | "form_submission_item"
  | "folder"
  | "gin";

export type AssetItemType = AssetWithOwnersEntity | AssetFolderEntity;

export type OwnerType = "admin" | "contact" | "company" | "provider";

export interface MappedAssets {
  [asset_id: string]: AssetEntity;
}
export interface AssetFolderEntity {
  record_id: string;
  record_name: string;
  updated_at: Date;
  created_at: Date;
  record_type: RecordType;
  asset_details: AssetEntity[];
  mapped_assets: { [asset_id: string]: AssetEntity };
}

export const InitialStateAssetFolderEntity: AssetFolderEntity = {
  record_id: "",
  record_name: "",
  updated_at: undefined,
  created_at: undefined,
  record_type: "contact",
  asset_details: [],
  mapped_assets: {},
};

export interface UploadFormValues {
  asset_id: string;
  file_name: string;
  file_description: string;
  version: string;
  record_id?: string;
  record_type?: RecordType;
}

export interface UploadAsset {
  asset: RcFile | undefined;
  values: UploadFormValues;
}

export interface UploadAssetResponse {
  file_name: string;
  version: string;
  asset_id: string;
  file_description: string;
  owners?: AssetOwnerInformation[];
  records?: AssetRecordInformation[];
}

export type Extension =
  | ".png"
  | ".jpg"
  | ".jpeg"
  | ".xls"
  | ".csv"
  | ".pdf"
  | ".doc"
  | ".gif";

export interface AssetEntity {
  asset_id: string;
  bundle_id?: string;
  file_name?: string;
  display_name?: string;
  extension?: Extension;
  description?: string;
  source?: string;
  application?: string;
  uploaded_at?: Date;
  created_at?: Date;
  deleted_at?: Date;
  opened_at?: Date;
  file_size?: string;
  version?: string;
  hidden?: boolean;
  last_uploaded_by?: AssetOwnerInformation;
}

export const InitialStateAssetEntity: AssetEntity = {
  asset_id: "",
};
export interface AssetWithOwnersEntity extends AssetEntity {
  owners?: AssetOwnerInformation[];
  records?: AssetRecordInformation[];
}

export interface AssetUploadResponse {
  asset: AssetEntity;
  folder_ids: string[];
}

export interface AssetOwnerInformation {
  owner_id: string;
  owner_type: OwnerType;
  owner_name?: string;
  owner_relation?: string;
}
export interface AssetRecordInformation {
  record_id: string;
  record_type: RecordType;
  record_name?: string;
}

export interface AssetDownloadResponse {
  asset: AssetEntity;
  link: string;
}

export interface AssetRowMetaActions {
  dropdownItems?: MenuProps["items"];
  onClick: () => void;
  onAction?: () => void;
  onDoubleClick: () => void;
  onDelete?: () => void;
  onHideShow?: (hide: boolean) => void;
}
export interface AssetRowMeta {
  id: string;
  name: string;
  description?: string;
  source?: string;
  created_at: Date;
  updated_at: Date;
  last_uploaded_by?: AssetOwnerInformation;
  opened_at?: Date;
  size: string;
  type: string;
  version?: string;
  Icon: IconNode;
  actions: AssetRowMetaActions;
  isFolder: boolean;
  original_asset: AssetItemType;
  hidden?: boolean;
}

export interface MappedAssetsMetas {
  [id: string]: AssetRowMeta;
}

