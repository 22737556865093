import { CSSProperties, ReactNode, useEffect } from "react";
import { useTheme } from "styled-components";
import { AppType } from "../../../../features/entities";
import { Breadcrumb } from "../../../../features/entities/context-entities";
import { useAppData } from "../../../../features/providers/app-data-provider";
import { useBreadcrumbs } from "../../../../features/providers/breadcrumbs-provider";
import Breadcrumbs from "../../../layout/header/breadcrumbs";
import Div from "../../div";

export interface AppHeaderProps {
  beforeHeader?: ReactNode;
  withoutPaddingRight?: boolean;
  withoutPaddingLeft?: boolean;
  withoutPaddingHorizontal?: boolean;
  app: AppType;
  breadcrumbs: Breadcrumb[];
}

interface DefaultAppHeaderProps extends AppHeaderProps {
  style?: CSSProperties;
}
const DefaultAppHeader = ({
  withoutPaddingHorizontal,
  withoutPaddingRight,
  withoutPaddingLeft,
  style,
  beforeHeader,
}: DefaultAppHeaderProps) => {
  const { header } = useAppData();

  return (
    <div
      style={{
        ...style,
        width: "100%",
        paddingRight: withoutPaddingHorizontal || withoutPaddingRight ? 60 : 0,
        paddingLeft: withoutPaddingHorizontal || withoutPaddingLeft ? 60 : 0,
      }}
    >
      {beforeHeader && <Div>{beforeHeader}</Div>}
      {header}
    </div>
  );
};

const AdminAppHeader = ({
  withoutPaddingRight,
  withoutPaddingHorizontal,
  style,
  beforeHeader,
}: DefaultAppHeaderProps) => {
  const theme = useTheme();
  const { header } = useAppData();
  const { breadCrumbs } = useBreadcrumbs();
  if (!header) return <></>;

  return (
    <div
      style={{
        ...style,
        position: "sticky",
        zIndex: 100,
        height: theme.appearance.appHeaderHeight,
        width: "100%",
        top: 0,
        transition: "left 200ms linear",
        paddingRight: withoutPaddingHorizontal || withoutPaddingRight ? 60 : 0,
        paddingLeft: withoutPaddingHorizontal ? 60 : 0,
        marginBottom: 16,
      }}
    >
      {!!beforeHeader && <div>{beforeHeader}</div>}
      {header}
      <div style={{ borderTop: `1px solid ${theme.colors.grey_5}` }} />
      {!!breadCrumbs.length && (
        <div
          style={{
            backdropFilter: "blur(4px)",
            padding: 6,
            backgroundColor: theme.colors.white_1,
            width: "100%",

            borderBottom: `1px solid ${theme.colors.grey_5}`,
          }}
        >
          <Breadcrumbs />
        </div>
      )}
    </div>
  );
};

const AppHeader = (props: DefaultAppHeaderProps) => {
  const { app, breadcrumbs } = props;

  const { setBreadCrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadCrumbs(breadcrumbs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs]);

  return app === "admin" ? (
    <AdminAppHeader {...props} />
  ) : (
    <DefaultAppHeader {...props} />
  );
};

export default AppHeader;

